/* Файл, который содержит стили со всеми темами и компонентами */
/* stylelint-disable */
/*
* Этот файл сгенерирован автоматически. Не надо править его руками.
*/
:root {
  --accent: #528bcc;
  --action_sheet_action_foreground: #2787f5;
  --action_sheet_separator: rgba(0, 0, 0, 0.12);
  --activity_indicator_tint: #aeb7c2;
  --attach_picker_tab_active_background: #5181b8;
  --attach_picker_tab_active_icon: #ffffff;
  --attach_picker_tab_active_text: #5181b8;
  --attach_picker_tab_inactive_background: #c4c8cc;
  --attach_picker_tab_inactive_icon: #ffffff;
  --attach_picker_tab_inactive_text: #909499;
  --background_content: #ffffff;
  --background_highlighted: rgba(0, 0, 0, 0.12);
  --background_keyboard: #e1e3e6;
  --background_light: #f9f9f9;
  --background_page: #ebedf0;
  --background_suggestions: #ffffff;
  --button_bot_shadow: #c4c8cc;
  --button_commerce_background: #4bb34b;
  --button_commerce_foreground: #ffffff;
  --button_muted_background: #f2f3f5;
  --button_muted_foreground: #5181b8;
  --button_outline_border: #528bcc;
  --button_outline_foreground: #528bcc;
  --button_primary_background: #5181b8;
  --button_primary_foreground: #ffffff;
  --button_secondary_background: rgba(0, 57, 115, 0.10);
  --button_secondary_foreground: #45678f;
  --button_tertiary_background: rgba(0, 0, 0, 0.00);
  --button_tertiary_foreground: #5181b8;
  --cell_button_foreground: #528bcc;
  --content_placeholder_icon: #99a2ad;
  --content_placeholder_text: #818c99;
  --content_tint_background: #f5f5f5;
  --content_tint_foreground: #7f8285;
  --control_background: #ebedf0;
  --control_foreground: #5181b8;
  --control_tint: #ffffff;
  --control_tint_muted: #76787a;
  --counter_primary_background: #528bcc;
  --counter_primary_text: #ffffff;
  --counter_prominent_background: #ff3347;
  --counter_prominent_text: #ffffff;
  --counter_secondary_background: #aaaeb3;
  --counter_secondary_text: #ffffff;
  --destructive: #e64646;
  --field_background: #f2f3f5;
  --field_border: rgba(0, 0, 0, 0.12);
  --field_error_background: #faebeb;
  --field_error_border: #e64646;
  --field_valid_border: #4bb34b;
  --field_text_placeholder: #909499;
  --float_button_background: #f5f5f5;
  --float_button_background_highlighted: #ebedf0;
  --float_button_foreground: #99a2ad;
  --float_button_border: rgba(0, 0, 0, 0.05);
  --header_alternate_background: #ffffff;
  --header_alternate_tab_active_indicator: #528bcc;
  --header_alternate_tab_active_text: #528bcc;
  --header_alternate_tab_inactive_text: #909499;
  --header_background: #5181b8;
  --header_background_before_blur: #346cad;
  --header_background_before_blur_alternate: #ffffff;
  --header_search_field_background: #28436e;
  --header_search_field_tint: #92b2d6;
  --header_tab_active_background: rgba(173, 211, 255, 0.32);
  --header_tab_active_text: #ffffff;
  --header_tab_active_indicator: #ffffff;
  --header_tab_inactive_text: rgba(194, 222, 255, 0.80);
  --header_text: #ffffff;
  --header_text_alternate: #000000;
  --header_text_secondary: rgba(255, 255, 255, 0.60);
  --header_tint: #ffffff;
  --header_tint_alternate: #528bcc;
  --icon_alpha_placeholder: #ffffff;
  --icon_medium: #909499;
  --icon_outline_medium: #818c99;
  --icon_outline_secondary: #99a2ad;
  --icon_secondary: #aaaeb3;
  --icon_tertiary: #c4c8cc;
  --im_attach_tint: #5181b8;
  --im_bubble_border_alternate: #e1e3e6;
  --im_bubble_border_alternate_highlighted: #d7d8d9;
  --im_bubble_button_background: #f9f9f9;
  --im_bubble_button_background_highlighted: #d7d8d9;
  --im_bubble_button_foreground: #000000;
  --im_bubble_incoming: #ebedf0;
  --im_bubble_incoming_alternate: #ffffff;
  --im_bubble_incoming_alternate_highlighted: #f9f9f9;
  --im_bubble_incoming_highlighted: #d7d8d9;
  --im_bubble_gift_background: #f4e4be;
  --im_bubble_gift_text: #7a6c4f;
  --im_bubble_gift_text_secondary: #9e8d6b;
  --im_bubble_outgoing: #cce4ff;
  --im_bubble_outgoing_alternate: #ffffff;
  --im_bubble_outgoing_highlighted: #add3ff;
  --im_bubble_outgoing_alternate_highlighted: #f9f9f9;
  --im_bubble_wallpaper_button_background: #f2f3f5;
  --im_bubble_wallpaper_button_background_highlighted: #e1e3e6;
  --im_bubble_wallpaper_button_foreground: #000000;
  --im_bubble_wallpaper_incoming: #ffffff;
  --im_bubble_wallpaper_incoming_highlighted: #ebedf0;
  --im_bubble_wallpaper_incoming_border: rgba(0, 57, 115, 0.10);
  --im_bubble_wallpaper_outgoing: #cce4ff;
  --im_bubble_wallpaper_outgoing_highlighted: #add3ff;
  --im_bubble_wallpaper_outgoing_border: rgba(0, 57, 115, 0.10);
  --im_forward_line_tint: #45678f;
  --im_reply_sender_text: #5181b8;
  --im_reply_separator: #5181b8;
  --im_toolbar_voice_msg_background: #99a2ad;
  --im_toolbar_separator: #001d3d;
  --image_border: rgba(0, 0, 0, 0.08);
  --input_background: #f2f3f5;
  --input_border: #e1e3e6;
  --landing_background: #528bcc;
  --landing_field_background: #ffffff;
  --landing_field_border: rgba(0, 0, 0, 0.12);
  --landing_field_placeholder: #818c99;
  --landing_login_button_background: rgba(0, 28, 61, 0.24);
  --landing_login_button_foreground: #ffffff;
  --landing_primary_button_background: #ffffff;
  --landing_primary_button_foreground: #2c2d2e;
  --landing_tertiary_button_background: rgba(0, 0, 0, 0.00);
  --landing_tertiary_button_foreground: rgba(194, 222, 255, 0.80);
  --landing_text_primary: #ffffff;
  --landing_text_title: #ffffff;
  --landing_text_secondary: rgba(255, 255, 255, 0.60);
  --landing_secondary_button_background: rgba(255, 255, 255, 0.15);
  --landing_secondary_button_foreground: #ffffff;
  --landing_snippet_border: rgba(0, 0, 0, 0.00);
  --like_text_tint: #ff3347;
  --link_alternate: #5181b8;
  --loader_background: #ebedf0;
  --loader_tint: #76787a;
  --loader_track_fill: #e1e3e6;
  --loader_track_value_fill: #528bcc;
  --media_overlay_button_background: #ffffff;
  --media_overlay_button_foreground: #2c2d2e;
  --modal_card_background: #ffffff;
  --modal_card_border: rgba(0, 0, 0, 0.00);
  --modal_card_header_close: #000000;
  --music_playback_icon: #000000;
  --feed_recommended_friend_promo_background: #528bcc;
  --overlay_status_background: #ebedf0;
  --overlay_status_foreground: #6d7885;
  --overlay_status_icon: #818c99;
  --placeholder_icon_background: rgba(0, 29, 61, 0.08);
  --placeholder_icon_foreground_primary: #99a2ad;
  --placeholder_icon_foreground_secondary: #aeb7c2;
  --placeholder_icon_tint: #001d3d;
  --poll_option_background: #45678f;
  --search_bar_background: #ebedf0;
  --search_bar_field_background: #ffffff;
  --search_bar_field_tint: #99a2ad;
  --search_bar_segmented_control_tint: #818c99;
  --segmented_control_bar_background: rgba(0, 0, 0, 0.00);
  --segmented_control_tint: #99a2ad;
  --selection_off_icon: #c4c8cc;
  --separator_alpha: rgba(0, 0, 0, 0.15);
  --separator_alternate: #e1e3e6;
  --separator_common: #d7d8d9;
  --snippet_background: #ffffff;
  --snippet_border: rgba(0, 0, 0, 0.15);
  --snippet_icon_tertiary: #c4c8cc;
  --splashscreen_icon: #e1e3e6;
  --statusbar_alternate_legacy_background: rgba(0, 0, 0, 0.30);
  --stories_create_button_background_from: #f2f3f5;
  --stories_create_button_background_to: #e1e3e6;
  --stories_create_button_foreground: #6d7885;
  --stories_skeleton_loader_background: #c4c8cc;
  --stories_create_button_icon_background: #ffffff;
  --switch_ios_off_border: #e1e3e6;
  --tabbar_active_icon: #2975cc;
  --tabbar_background: #f9f9f9;
  --tabbar_inactive_icon: #99a2ad;
  --tabbar_tablet_active_icon: #71aaeb;
  --tabbar_tablet_background: #384452;
  --tabbar_tablet_inactive_icon: #aeb7c2;
  --tabbar_tablet_text_primary: #ebedf0;
  --tabbar_tablet_text_secondary: rgba(255, 255, 255, 0.60);
  --toolbar_attach_background_from: #b8c1cc;
  --toolbar_attach_background_to: #a3adb8;
  --text_action_counter: #818c99;
  --text_link: #3c6a9e;
  --text_link_hightlighted_background: #000000;
  --text_muted: #2c2d2e;
  --text_name: #45678f;
  --text_placeholder: #818c99;
  --text_primary: #000000;
  --text_secondary: #909499;
  --text_subhead: #76787a;
  --text_tertiary: #aaaeb3;
  --icon_name: #74a2d6;
  --panel_tab_active_background: #ebedf0;
  --panel_tab_active_text: #5d5f61;
  --panel_tab_inactive_text: #76787a;
  --im_service_message_text: #818c99;
  --writebar_icon: #99a2ad;
  --dynamic_blue: #528bcc;
  --dynamic_gray: #a3adb8;
  --dynamic_red: #ff3347;
  --dynamic_green: #4bb34b;
  --dynamic_orange: #ffa000;
  --dynamic_violet: #792ec0;
}
/* stylelint-enable */
/* stylelint-disable */
/*
* Этот файл сгенерирован автоматически. Не надо править его руками.
*/
body[scheme="client_dark"] {
  --accent: #71aaeb;
  --action_sheet_action_foreground: #ffffff;
  --action_sheet_separator: rgba(255, 255, 255, 0.12);
  --activity_indicator_tint: #5d5f61;
  --attach_picker_tab_active_background: #e1e3e6;
  --attach_picker_tab_active_icon: #19191a;
  --attach_picker_tab_active_text: #e1e3e6;
  --attach_picker_tab_inactive_background: #2c2d2e;
  --attach_picker_tab_inactive_icon: #e1e3e6;
  --attach_picker_tab_inactive_text: #76787a;
  --background_content: #19191a;
  --background_highlighted: rgba(255, 255, 255, 0.12);
  --background_keyboard: #2c2d2e;
  --background_light: #232324;
  --background_page: #0a0a0a;
  --background_suggestions: #2c2d2e;
  --button_bot_shadow: #19191a;
  --button_commerce_background: #4bb34b;
  --button_commerce_foreground: #ffffff;
  --button_muted_background: #2c2d2e;
  --button_muted_foreground: #e1e3e6;
  --button_outline_border: #e1e3e6;
  --button_outline_foreground: #e1e3e6;
  --button_primary_background: #e1e3e6;
  --button_primary_foreground: #19191a;
  --button_secondary_background: #454647;
  --button_secondary_foreground: #e1e3e6;
  --button_tertiary_background: rgba(0, 0, 0, 0.00);
  --button_tertiary_foreground: #e1e3e6;
  --cell_button_foreground: #e1e3e6;
  --content_placeholder_icon: #aaaeb3;
  --content_placeholder_text: #aaaeb3;
  --content_tint_background: #232324;
  --content_tint_foreground: #909499;
  --control_background: #454647;
  --control_foreground: #e1e3e6;
  --control_tint: #19191a;
  --control_tint_muted: #909499;
  --counter_primary_background: #71aaeb;
  --counter_primary_text: #ffffff;
  --counter_prominent_background: #ff3347;
  --counter_prominent_text: #ffffff;
  --counter_secondary_background: #76787a;
  --counter_secondary_text: #19191a;
  --destructive: #ff4e5f;
  --field_background: #232324;
  --field_border: rgba(255, 255, 255, 0.12);
  --field_error_background: #522e2e;
  --field_error_border: #ff4e5f;
  --field_valid_border: #4bb34b;
  --field_text_placeholder: #76787a;
  --float_button_background: #454647;
  --float_button_background_highlighted: #5d5f61;
  --float_button_foreground: #aaaeb3;
  --float_button_border: rgba(0, 0, 0, 0.12);
  --header_alternate_background: #2c2d2e;
  --header_alternate_tab_active_indicator: #e1e3e6;
  --header_alternate_tab_active_text: #e1e3e6;
  --header_alternate_tab_inactive_text: #76787a;
  --header_background: #2c2d2e;
  --header_background_before_blur: #141414;
  --header_background_before_blur_alternate: #141414;
  --header_search_field_background: #454647;
  --header_search_field_tint: #aaaeb3;
  --header_tab_active_background: #5d5f61;
  --header_tab_active_text: #e1e3e6;
  --header_tab_active_indicator: #e1e3e6;
  --header_tab_inactive_text: #76787a;
  --header_text: #e1e3e6;
  --header_text_alternate: #e1e3e6;
  --header_text_secondary: rgba(255, 255, 255, 0.60);
  --header_tint: #e1e3e6;
  --header_tint_alternate: #e1e3e6;
  --icon_alpha_placeholder: #e1e3e6;
  --icon_medium: #909499;
  --icon_outline_medium: #aaaeb3;
  --icon_outline_secondary: #909499;
  --icon_secondary: #76787a;
  --icon_tertiary: #454647;
  --im_attach_tint: #71aaeb;
  --im_bubble_border_alternate: rgba(0, 0, 0, 0.00);
  --im_bubble_border_alternate_highlighted: rgba(0, 0, 0, 0.00);
  --im_bubble_button_background: #454647;
  --im_bubble_button_background_highlighted: #76787a;
  --im_bubble_button_foreground: #e1e3e6;
  --im_bubble_incoming: #2c2d2e;
  --im_bubble_incoming_alternate: #2c2d2e;
  --im_bubble_incoming_alternate_highlighted: #5d5f61;
  --im_bubble_incoming_highlighted: #5d5f61;
  --im_bubble_gift_background: #9e8d6b;
  --im_bubble_gift_text: #fffbf2;
  --im_bubble_gift_text_secondary: #f4e4be;
  --im_bubble_outgoing: #454647;
  --im_bubble_outgoing_alternate: #454647;
  --im_bubble_outgoing_highlighted: #5d5f61;
  --im_bubble_outgoing_alternate_highlighted: #76787a;
  --im_bubble_wallpaper_button_background: #454647;
  --im_bubble_wallpaper_button_background_highlighted: #76787a;
  --im_bubble_wallpaper_button_foreground: #e1e3e6;
  --im_bubble_wallpaper_incoming: #2c2d2e;
  --im_bubble_wallpaper_incoming_highlighted: #5d5f61;
  --im_bubble_wallpaper_incoming_border: #19191a;
  --im_bubble_wallpaper_outgoing: #454647;
  --im_bubble_wallpaper_outgoing_highlighted: #5d5f61;
  --im_bubble_wallpaper_outgoing_border: #2c2d2e;
  --im_forward_line_tint: #ffffff;
  --im_reply_sender_text: #ffffff;
  --im_reply_separator: #ffffff;
  --im_toolbar_voice_msg_background: #454647;
  --im_toolbar_separator: #aaaeb3;
  --image_border: rgba(255, 255, 255, 0.08);
  --input_background: #454647;
  --input_border: #454647;
  --landing_background: #19191a;
  --landing_field_background: #232324;
  --landing_field_border: rgba(255, 255, 255, 0.12);
  --landing_field_placeholder: #aaaeb3;
  --landing_login_button_background: #e1e3e6;
  --landing_login_button_foreground: #19191a;
  --landing_primary_button_background: #e1e3e6;
  --landing_primary_button_foreground: #19191a;
  --landing_tertiary_button_background: rgba(0, 0, 0, 0.00);
  --landing_tertiary_button_foreground: #76787a;
  --landing_text_primary: #e1e3e6;
  --landing_text_title: #ffffff;
  --landing_text_secondary: #76787a;
  --landing_secondary_button_background: rgba(255, 255, 255, 0.15);
  --landing_secondary_button_foreground: #ffffff;
  --landing_snippet_border: rgba(0, 0, 0, 0.00);
  --like_text_tint: #ff4e5f;
  --link_alternate: #71aaeb;
  --loader_background: #19191a;
  --loader_tint: #76787a;
  --loader_track_fill: #454647;
  --loader_track_value_fill: #71aaeb;
  --media_overlay_button_background: #ffffff;
  --media_overlay_button_foreground: #2c2d2e;
  --modal_card_background: #2c2d2e;
  --modal_card_border: rgba(255, 255, 255, 0.12);
  --modal_card_header_close: #ffffff;
  --music_playback_icon: #ffffff;
  --feed_recommended_friend_promo_background: #6d7885;
  --overlay_status_background: #19191a;
  --overlay_status_foreground: #e1e3e6;
  --overlay_status_icon: #ebedf0;
  --placeholder_icon_background: rgba(255, 255, 255, 0.08);
  --placeholder_icon_foreground_primary: #909499;
  --placeholder_icon_foreground_secondary: #5d5f61;
  --placeholder_icon_tint: #ffffff;
  --poll_option_background: #ffffff;
  --search_bar_background: #19191a;
  --search_bar_field_background: #454647;
  --search_bar_field_tint: #aaaeb3;
  --search_bar_segmented_control_tint: #909499;
  --segmented_control_bar_background: #19191a;
  --segmented_control_tint: #76787a;
  --selection_off_icon: #454647;
  --separator_alpha: rgba(255, 255, 255, 0.15);
  --separator_alternate: #232324;
  --separator_common: #313233;
  --snippet_background: #2c2d2e;
  --snippet_border: rgba(255, 255, 255, 0.15);
  --snippet_icon_tertiary: #5d5f61;
  --splashscreen_icon: #2c2d2e;
  --statusbar_alternate_legacy_background: rgba(0, 0, 0, 0.00);
  --stories_create_button_background_from: #313233;
  --stories_create_button_background_to: #313233;
  --stories_create_button_foreground: #c4c8cc;
  --stories_skeleton_loader_background: #454647;
  --stories_create_button_icon_background: #454647;
  --switch_ios_off_border: #2c2d2e;
  --tabbar_active_icon: #ffffff;
  --tabbar_background: #2c2d2e;
  --tabbar_inactive_icon: #76787a;
  --tabbar_tablet_active_icon: #71aaeb;
  --tabbar_tablet_background: #232324;
  --tabbar_tablet_inactive_icon: #76787a;
  --tabbar_tablet_text_primary: #e1e3e6;
  --tabbar_tablet_text_secondary: #76787a;
  --toolbar_attach_background_from: #76787a;
  --toolbar_attach_background_to: #5d5f61;
  --text_action_counter: #aaaeb3;
  --text_link: #71aaeb;
  --text_link_hightlighted_background: #71aaeb;
  --text_muted: #c4c8cc;
  --text_name: #e1e3e6;
  --text_placeholder: #aaaeb3;
  --text_primary: #e1e3e6;
  --text_secondary: #76787a;
  --text_subhead: #909499;
  --text_tertiary: #5d5f61;
  --icon_name: #aaaeb3;
  --panel_tab_active_background: #454647;
  --panel_tab_active_text: #e1e3e6;
  --panel_tab_inactive_text: #76787a;
  --im_service_message_text: #818c99;
  --writebar_icon: #909499;
  --dynamic_blue: #528bcc;
  --dynamic_gray: #a3adb8;
  --dynamic_red: #ff3347;
  --dynamic_green: #4bb34b;
  --dynamic_orange: #ffa000;
  --dynamic_violet: #928dab;
}
/* stylelint-enable */
/* stylelint-disable */
/*
* Этот файл сгенерирован автоматически. Не надо править его руками.
*/
body[scheme="bright_light"] {
  --accent: #3f8ae0;
  --action_sheet_action_foreground: #3f8ae0;
  --action_sheet_separator: rgba(0, 0, 0, 0.12);
  --activity_indicator_tint: #aeb7c2;
  --attach_picker_tab_active_background: #4986cc;
  --attach_picker_tab_active_icon: #ffffff;
  --attach_picker_tab_active_text: #4986cc;
  --attach_picker_tab_inactive_background: #f2f3f5;
  --attach_picker_tab_inactive_icon: #818c99;
  --attach_picker_tab_inactive_text: #818c99;
  --background_content: #ffffff;
  --background_highlighted: rgba(0, 0, 0, 0.12);
  --background_keyboard: #e1e3e6;
  --background_light: #f9f9f9;
  --background_page: #ebedf0;
  --background_suggestions: #ffffff;
  --button_bot_shadow: #c4c8cc;
  --button_commerce_background: #4bb34b;
  --button_commerce_foreground: #ffffff;
  --button_muted_background: #f2f3f5;
  --button_muted_foreground: #5181b8;
  --button_outline_border: #528bcc;
  --button_outline_foreground: #528bcc;
  --button_primary_background: #4986cc;
  --button_primary_foreground: #ffffff;
  --button_secondary_background: rgba(0, 57, 115, 0.10);
  --button_secondary_foreground: #4986cc;
  --button_tertiary_background: rgba(0, 0, 0, 0.00);
  --button_tertiary_foreground: #4986cc;
  --cell_button_foreground: #528bcc;
  --content_placeholder_icon: #99a2ad;
  --content_placeholder_text: #818c99;
  --content_tint_background: #f5f5f5;
  --content_tint_foreground: #7f8285;
  --control_background: #ebedf0;
  --control_foreground: #5181b8;
  --control_tint: #ffffff;
  --control_tint_muted: #76787a;
  --counter_primary_background: #3f8ae0;
  --counter_primary_text: #ffffff;
  --counter_prominent_background: #ff3347;
  --counter_prominent_text: #ffffff;
  --counter_secondary_background: #a3adb8;
  --counter_secondary_text: #ffffff;
  --destructive: #e64646;
  --field_background: #f2f3f5;
  --field_border: rgba(0, 0, 0, 0.12);
  --field_error_background: #faebeb;
  --field_error_border: #e64646;
  --field_valid_border: #4bb34b;
  --field_text_placeholder: #909499;
  --float_button_background: #f5f5f5;
  --float_button_background_highlighted: #ebedf0;
  --float_button_foreground: #99a2ad;
  --float_button_border: rgba(0, 0, 0, 0.05);
  --header_alternate_background: #ffffff;
  --header_alternate_tab_active_indicator: #3f8ae0;
  --header_alternate_tab_active_text: #3f8ae0;
  --header_alternate_tab_inactive_text: #99a2ad;
  --header_background: #ffffff;
  --header_background_before_blur: #ffffff;
  --header_background_before_blur_alternate: #ffffff;
  --header_search_field_background: #f5f5f5;
  --header_search_field_tint: #818c99;
  --header_tab_active_background: rgba(173, 211, 255, 0.32);
  --header_tab_active_text: #4986cc;
  --header_tab_active_indicator: #4986cc;
  --header_tab_inactive_text: #9099a3;
  --header_text: #000000;
  --header_text_alternate: #000000;
  --header_text_secondary: #818c99;
  --header_tint: #3f8ae0;
  --header_tint_alternate: #3f8ae0;
  --icon_alpha_placeholder: #ffffff;
  --icon_medium: #818c99;
  --icon_outline_medium: #818c99;
  --icon_outline_secondary: #a3adb8;
  --icon_secondary: #a3adb8;
  --icon_tertiary: #c4c8cc;
  --im_attach_tint: #4986cc;
  --im_bubble_border_alternate: #e1e3e6;
  --im_bubble_border_alternate_highlighted: #d7d8d9;
  --im_bubble_button_background: #f9f9f9;
  --im_bubble_button_background_highlighted: #d7d8d9;
  --im_bubble_button_foreground: #000000;
  --im_bubble_incoming: #ebedf0;
  --im_bubble_incoming_alternate: #ffffff;
  --im_bubble_incoming_alternate_highlighted: #f9f9f9;
  --im_bubble_incoming_highlighted: #d7d8d9;
  --im_bubble_gift_background: #f4e4be;
  --im_bubble_gift_text: #7a6c4f;
  --im_bubble_gift_text_secondary: #9e8d6b;
  --im_bubble_outgoing: #cce4ff;
  --im_bubble_outgoing_alternate: #ffffff;
  --im_bubble_outgoing_highlighted: #add3ff;
  --im_bubble_outgoing_alternate_highlighted: #f9f9f9;
  --im_bubble_wallpaper_button_background: #f2f3f5;
  --im_bubble_wallpaper_button_background_highlighted: #e1e3e6;
  --im_bubble_wallpaper_button_foreground: #000000;
  --im_bubble_wallpaper_incoming: #ffffff;
  --im_bubble_wallpaper_incoming_highlighted: #ebedf0;
  --im_bubble_wallpaper_incoming_border: rgba(0, 57, 115, 0.10);
  --im_bubble_wallpaper_outgoing: #cce4ff;
  --im_bubble_wallpaper_outgoing_highlighted: #add3ff;
  --im_bubble_wallpaper_outgoing_border: rgba(0, 57, 115, 0.10);
  --im_forward_line_tint: #45678f;
  --im_reply_sender_text: #4986cc;
  --im_reply_separator: #4986cc;
  --im_toolbar_voice_msg_background: #99a2ad;
  --im_toolbar_separator: #001d3d;
  --image_border: rgba(0, 0, 0, 0.08);
  --input_background: #f2f3f5;
  --input_border: #e1e3e6;
  --landing_background: #ffffff;
  --landing_field_background: #f2f3f5;
  --landing_field_border: rgba(0, 0, 0, 0.00);
  --landing_field_placeholder: #818c99;
  --landing_login_button_background: #3f8ae0;
  --landing_login_button_foreground: #ffffff;
  --landing_primary_button_background: #3f8ae0;
  --landing_primary_button_foreground: #ffffff;
  --landing_tertiary_button_background: rgba(0, 0, 0, 0.00);
  --landing_tertiary_button_foreground: #3f8ae0;
  --landing_text_primary: #000000;
  --landing_text_title: #3f8ae0;
  --landing_text_secondary: #818c99;
  --landing_secondary_button_background: rgba(0, 57, 115, 0.10);
  --landing_secondary_button_foreground: #4986cc;
  --landing_snippet_border: rgba(0, 0, 0, 0.15);
  --like_text_tint: #ff3347;
  --link_alternate: #4986cc;
  --loader_background: #ebedf0;
  --loader_tint: #76787a;
  --loader_track_fill: #e1e3e6;
  --loader_track_value_fill: #528bcc;
  --media_overlay_button_background: #ffffff;
  --media_overlay_button_foreground: #2c2d2e;
  --modal_card_background: #ffffff;
  --modal_card_border: rgba(0, 0, 0, 0.00);
  --modal_card_header_close: #000000;
  --music_playback_icon: #000000;
  --feed_recommended_friend_promo_background: #528bcc;
  --overlay_status_background: #ebedf0;
  --overlay_status_foreground: #6d7885;
  --overlay_status_icon: #818c99;
  --placeholder_icon_background: rgba(0, 29, 61, 0.08);
  --placeholder_icon_foreground_primary: #99a2ad;
  --placeholder_icon_foreground_secondary: #aeb7c2;
  --placeholder_icon_tint: #001d3d;
  --poll_option_background: #45678f;
  --search_bar_background: rgba(0, 0, 0, 0.00);
  --search_bar_field_background: #f5f5f5;
  --search_bar_field_tint: #818c99;
  --search_bar_segmented_control_tint: #818c99;
  --segmented_control_bar_background: rgba(0, 0, 0, 0.00);
  --segmented_control_tint: #99a2ad;
  --selection_off_icon: #b8c1cc;
  --separator_alpha: rgba(0, 0, 0, 0.15);
  --separator_alternate: #e1e3e6;
  --separator_common: #d7d8d9;
  --snippet_background: #ffffff;
  --snippet_border: rgba(0, 0, 0, 0.15);
  --snippet_icon_tertiary: #c4c8cc;
  --splashscreen_icon: #e1e3e6;
  --statusbar_alternate_legacy_background: rgba(0, 0, 0, 0.30);
  --stories_create_button_background_from: #f2f3f5;
  --stories_create_button_background_to: #e1e3e6;
  --stories_create_button_foreground: #6d7885;
  --stories_skeleton_loader_background: #c4c8cc;
  --switch_ios_off_border: #e1e3e6;
  --stories_create_button_icon_background: #ffffff;
  --tabbar_active_icon: #2975cc;
  --tabbar_background: #f9f9f9;
  --tabbar_inactive_icon: #99a2ad;
  --tabbar_tablet_active_icon: #2975cc;
  --tabbar_tablet_background: #f9f9f9;
  --tabbar_tablet_inactive_icon: #99a2ad;
  --tabbar_tablet_text_primary: #000000;
  --tabbar_tablet_text_secondary: #909499;
  --toolbar_attach_background_from: #b8c1cc;
  --toolbar_attach_background_to: #a3adb8;
  --text_action_counter: #818c99;
  --text_link: #4986cc;
  --text_link_hightlighted_background: #000000;
  --text_muted: #2c2d2e;
  --text_name: #4986cc;
  --text_placeholder: #818c99;
  --text_primary: #000000;
  --text_secondary: #818c99;
  --text_subhead: #6d7885;
  --text_tertiary: #99a2ad;
  --icon_name: #74a2d6;
  --panel_tab_active_background: #ebedf0;
  --panel_tab_active_text: #5d5f61;
  --panel_tab_inactive_text: #76787a;
  --im_service_message_text: #818c99;
  --writebar_icon: #3f8ae0;
  --dynamic_blue: #3f8ae0;
  --dynamic_gray: #a3adb8;
  --dynamic_red: #ff3347;
  --dynamic_green: #4bb34b;
  --dynamic_orange: #ffa000;
  --dynamic_violet: #792ec0;
}
/* stylelint-enable */
/* stylelint-disable */
/*
* Этот файл сгенерирован автоматически. Не надо править его руками.
*/
body[scheme="space_gray"] {
  --accent: #71aaeb;
  --action_sheet_action_foreground: #ffffff;
  --action_sheet_separator: rgba(255, 255, 255, 0.12);
  --activity_indicator_tint: #5d5f61;
  --attach_picker_tab_active_background: #e1e3e6;
  --attach_picker_tab_active_icon: #19191a;
  --attach_picker_tab_active_text: #e1e3e6;
  --attach_picker_tab_inactive_background: #2c2d2e;
  --attach_picker_tab_inactive_icon: #e1e3e6;
  --attach_picker_tab_inactive_text: #76787a;
  --background_content: #19191a;
  --background_highlighted: rgba(255, 255, 255, 0.12);
  --background_keyboard: #2c2d2e;
  --background_light: #232324;
  --background_page: #0a0a0a;
  --background_suggestions: #2c2d2e;
  --button_bot_shadow: #19191a;
  --button_commerce_background: #4bb34b;
  --button_commerce_foreground: #ffffff;
  --button_muted_background: #2c2d2e;
  --button_muted_foreground: #e1e3e6;
  --button_outline_border: #e1e3e6;
  --button_outline_foreground: #e1e3e6;
  --button_primary_background: #e1e3e6;
  --button_primary_foreground: #19191a;
  --button_secondary_background: #454647;
  --button_secondary_foreground: #e1e3e6;
  --button_tertiary_background: rgba(0, 0, 0, 0.00);
  --button_tertiary_foreground: #e1e3e6;
  --cell_button_foreground: #e1e3e6;
  --content_placeholder_icon: #aaaeb3;
  --content_placeholder_text: #aaaeb3;
  --content_tint_background: #232324;
  --content_tint_foreground: #909499;
  --control_background: #454647;
  --control_foreground: #e1e3e6;
  --control_tint: #19191a;
  --control_tint_muted: #909499;
  --counter_primary_background: #ffffff;
  --counter_primary_text: #19191a;
  --counter_prominent_background: #ff3347;
  --counter_prominent_text: #ffffff;
  --counter_secondary_background: #76787a;
  --counter_secondary_text: #19191a;
  --destructive: #ff4e5f;
  --field_background: #232324;
  --field_border: rgba(255, 255, 255, 0.12);
  --field_error_background: #522e2e;
  --field_error_border: #ff4e5f;
  --field_valid_border: #4bb34b;
  --field_text_placeholder: #76787a;
  --float_button_background: #454647;
  --float_button_background_highlighted: #5d5f61;
  --float_button_foreground: #aaaeb3;
  --float_button_border: rgba(0, 0, 0, 0.12);
  --header_alternate_background: #2c2d2e;
  --header_alternate_tab_active_indicator: #e1e3e6;
  --header_alternate_tab_active_text: #e1e3e6;
  --header_alternate_tab_inactive_text: #76787a;
  --header_background: #19191a;
  --header_background_before_blur: #070708;
  --header_background_before_blur_alternate: #070708;
  --header_search_field_background: #454647;
  --header_search_field_tint: #aaaeb3;
  --header_tab_active_background: #5d5f61;
  --header_tab_active_text: #ffffff;
  --header_tab_active_indicator: #ffffff;
  --header_tab_inactive_text: #76787a;
  --header_text: #e1e3e6;
  --header_text_alternate: #e1e3e6;
  --header_text_secondary: rgba(255, 255, 255, 0.60);
  --header_tint: #e1e3e6;
  --header_tint_alternate: #e1e3e6;
  --icon_alpha_placeholder: #e1e3e6;
  --icon_medium: #909499;
  --icon_outline_medium: #aaaeb3;
  --icon_outline_secondary: #909499;
  --icon_secondary: #76787a;
  --icon_tertiary: #454647;
  --im_attach_tint: #ffffff;
  --im_bubble_border_alternate: rgba(0, 0, 0, 0.00);
  --im_bubble_border_alternate_highlighted: rgba(0, 0, 0, 0.00);
  --im_bubble_button_background: #454647;
  --im_bubble_button_background_highlighted: #76787a;
  --im_bubble_button_foreground: #e1e3e6;
  --im_bubble_incoming: #2c2d2e;
  --im_bubble_incoming_alternate: #2c2d2e;
  --im_bubble_incoming_alternate_highlighted: #5d5f61;
  --im_bubble_incoming_highlighted: #5d5f61;
  --im_bubble_gift_background: #9e8d6b;
  --im_bubble_gift_text: #fffbf2;
  --im_bubble_gift_text_secondary: #f4e4be;
  --im_bubble_outgoing: #454647;
  --im_bubble_outgoing_alternate: #454647;
  --im_bubble_outgoing_highlighted: #5d5f61;
  --im_bubble_outgoing_alternate_highlighted: #76787a;
  --im_bubble_wallpaper_button_background: #454647;
  --im_bubble_wallpaper_button_background_highlighted: #76787a;
  --im_bubble_wallpaper_button_foreground: #e1e3e6;
  --im_bubble_wallpaper_incoming: #2c2d2e;
  --im_bubble_wallpaper_incoming_highlighted: #5d5f61;
  --im_bubble_wallpaper_incoming_border: #19191a;
  --im_bubble_wallpaper_outgoing: #454647;
  --im_bubble_wallpaper_outgoing_highlighted: #5d5f61;
  --im_bubble_wallpaper_outgoing_border: #2c2d2e;
  --im_forward_line_tint: #ffffff;
  --im_reply_sender_text: #ffffff;
  --im_reply_separator: #ffffff;
  --im_toolbar_voice_msg_background: #454647;
  --im_toolbar_separator: #aaaeb3;
  --image_border: rgba(255, 255, 255, 0.08);
  --input_background: #2c2d2e;
  --input_border: #2c2d2e;
  --landing_background: #19191a;
  --landing_field_background: #232324;
  --landing_field_border: rgba(0, 0, 0, 0.00);
  --landing_field_placeholder: #aaaeb3;
  --landing_login_button_background: #e1e3e6;
  --landing_login_button_foreground: #19191a;
  --landing_primary_button_background: #e1e3e6;
  --landing_primary_button_foreground: #19191a;
  --landing_tertiary_button_background: rgba(0, 0, 0, 0.00);
  --landing_tertiary_button_foreground: #ffffff;
  --landing_text_primary: #e1e3e6;
  --landing_text_title: #ffffff;
  --landing_text_secondary: #76787a;
  --landing_secondary_button_background: rgba(255, 255, 255, 0.15);
  --landing_secondary_button_foreground: #ffffff;
  --landing_snippet_border: rgba(255, 255, 255, 0.15);
  --like_text_tint: #ff4e5f;
  --link_alternate: #ffffff;
  --loader_background: #19191a;
  --loader_tint: #76787a;
  --loader_track_fill: #454647;
  --loader_track_value_fill: #ffffff;
  --media_overlay_button_background: #ffffff;
  --media_overlay_button_foreground: #2c2d2e;
  --modal_card_background: #2c2d2e;
  --modal_card_border: rgba(255, 255, 255, 0.12);
  --modal_card_header_close: #ffffff;
  --music_playback_icon: #ffffff;
  --feed_recommended_friend_promo_background: #6d7885;
  --overlay_status_background: #19191a;
  --overlay_status_foreground: #e1e3e6;
  --overlay_status_icon: #ebedf0;
  --placeholder_icon_background: rgba(255, 255, 255, 0.08);
  --placeholder_icon_foreground_primary: #909499;
  --placeholder_icon_foreground_secondary: #5d5f61;
  --placeholder_icon_tint: #ffffff;
  --poll_option_background: #ffffff;
  --search_bar_background: rgba(0, 0, 0, 0.00);
  --search_bar_field_background: #454647;
  --search_bar_field_tint: #aaaeb3;
  --search_bar_segmented_control_tint: #909499;
  --segmented_control_bar_background: #19191a;
  --segmented_control_tint: #76787a;
  --selection_off_icon: #454647;
  --separator_alpha: rgba(255, 255, 255, 0.15);
  --separator_alternate: #232324;
  --separator_common: #313233;
  --snippet_background: #2c2d2e;
  --snippet_border: rgba(255, 255, 255, 0.15);
  --snippet_icon_tertiary: #5d5f61;
  --splashscreen_icon: #2c2d2e;
  --statusbar_alternate_legacy_background: rgba(0, 0, 0, 0.00);
  --stories_create_button_background_from: #313233;
  --stories_create_button_background_to: #313233;
  --stories_create_button_foreground: #c4c8cc;
  --stories_skeleton_loader_background: #454647;
  --stories_create_button_icon_background: #454647;
  --switch_ios_off_border: #2c2d2e;
  --tabbar_active_icon: #ffffff;
  --tabbar_background: #2c2d2e;
  --tabbar_inactive_icon: #76787a;
  --tabbar_tablet_active_icon: #71aaeb;
  --tabbar_tablet_background: #232324;
  --tabbar_tablet_inactive_icon: #76787a;
  --tabbar_tablet_text_primary: #e1e3e6;
  --tabbar_tablet_text_secondary: #76787a;
  --toolbar_attach_background_from: #76787a;
  --toolbar_attach_background_to: #5d5f61;
  --text_action_counter: #aaaeb3;
  --text_link: #71aaeb;
  --text_link_hightlighted_background: #71aaeb;
  --text_muted: #c4c8cc;
  --text_name: #e1e3e6;
  --text_placeholder: #aaaeb3;
  --text_primary: #e1e3e6;
  --text_secondary: #76787a;
  --text_subhead: #909499;
  --text_tertiary: #5d5f61;
  --icon_name: #aaaeb3;
  --panel_tab_active_background: #454647;
  --panel_tab_active_text: #e1e3e6;
  --panel_tab_inactive_text: #76787a;
  --im_service_message_text: #76787a;
  --writebar_icon: #909499;
  --dynamic_blue: #3f8ae0;
  --dynamic_gray: #a3adb8;
  --dynamic_red: #ff3347;
  --dynamic_green: #4bb34b;
  --dynamic_orange: #ffa000;
  --dynamic_violet: #928dab;
}
/* stylelint-enable */
:root {
  /* fonts */
  --font-ios: -apple-system, Helvetica Neue, Arial;
  --font-android: Roboto, Arial;
  --font-common: -apple-system, Roboto, Helvetica Neue, Arial;

  /* easings */
  --ios-easing: cubic-bezier(.36, .66, .04, 1);
  --android-easing: cubic-bezier(.4, 0, .2, 1);

  /* sizes */
  --tabbar_height: 48px;
  --panelheader_height_ios: 44px;
  --panelheader_height_android: 56px;
  --modalheader_height_ios: 52px;
  --modalheader_height_android: 56px;

  /* colors */
  --white: #fff;

  /* iOS insets */
  --safe-area-inset-top: 20px;
  --safe-area-inset-bottom: 0px;
}
@supports (padding-top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  }
}
@supports (padding-top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
  }
}
@-webkit-keyframes animation-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes animation-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
/* Файлы с CSS компонентов подключаются здесь неслучайно. Пока не удалось делать импорты через JS так,
 * чтобы в каждом файле были доступны css переменные без их дублирования.
 */
/* Layout */
.Root {
  width: 100%;
  height: 100%;
  position: relative;
  }
.Root--transition {
  pointer-events: none;
  }
.Root__view {
    width: 100%;
    height: 100%;
    position: relative;
    }
.Root--transition .Root__view:not(.Root__view--hide-forward) {
    position: fixed;
    left: 0;
    top: 0;
    }
.Root--transition .Root__view:not(.Root__view--hide-forward) .View__panel {
    overflow: hidden;
    }
.Root__view--show-back,
  .Root__view--hide-forward {
    z-index: 1;
    }
.Root__view--show-forward,
  .Root__view--hide-back {
    z-index: 2;
    }
.Root__popout {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    }
/**
 * iOS
 */
.Root--ios {
  font-family: -apple-system, Helvetica Neue, Arial;
  font-family: -apple-system, Helvetica Neue, Arial;
  font-family: var(--font-ios);
  }
.Root--ios .Root__view--show-back::after,
  .Root--ios .Root__view--hide-forward::after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    content: '';
    background-color: #000;
    z-index: 6;
    }
.Root--ios .Root__view--show-back::after {
    -webkit-animation: root-ios-animation-show-back .6s cubic-bezier(.36, .66, .04, 1);
            animation: root-ios-animation-show-back .6s cubic-bezier(.36, .66, .04, 1);
    -webkit-animation: root-ios-animation-show-back .6s cubic-bezier(.36, .66, .04, 1);
    -webkit-animation: root-ios-animation-show-back .6s var(--ios-easing);
            animation: root-ios-animation-show-back .6s cubic-bezier(.36, .66, .04, 1);
            animation: root-ios-animation-show-back .6s var(--ios-easing);
    }
.Root--ios .Root__view--hide-forward::after {
    -webkit-animation: root-ios-animation-hide-forward .6s cubic-bezier(.36, .66, .04, 1);
            animation: root-ios-animation-hide-forward .6s cubic-bezier(.36, .66, .04, 1);
    -webkit-animation: root-ios-animation-hide-forward .6s cubic-bezier(.36, .66, .04, 1);
    -webkit-animation: root-ios-animation-hide-forward .6s var(--ios-easing);
            animation: root-ios-animation-hide-forward .6s cubic-bezier(.36, .66, .04, 1);
            animation: root-ios-animation-hide-forward .6s var(--ios-easing);
    }
.Root--ios .Root__view--show-forward {
    -webkit-animation: root-ios-animation-show-forward .6s cubic-bezier(.36, .66, .04, 1);
            animation: root-ios-animation-show-forward .6s cubic-bezier(.36, .66, .04, 1);
    -webkit-animation: root-ios-animation-show-forward .6s cubic-bezier(.36, .66, .04, 1);
    -webkit-animation: root-ios-animation-show-forward .6s var(--ios-easing);
            animation: root-ios-animation-show-forward .6s cubic-bezier(.36, .66, .04, 1);
            animation: root-ios-animation-show-forward .6s var(--ios-easing);
    }
.Root--ios .Root__view--hide-back {
    -webkit-animation: root-ios-animation-hide-back .6s cubic-bezier(.36, .66, .04, 1);
            animation: root-ios-animation-hide-back .6s cubic-bezier(.36, .66, .04, 1);
    -webkit-animation: root-ios-animation-hide-back .6s cubic-bezier(.36, .66, .04, 1);
    -webkit-animation: root-ios-animation-hide-back .6s var(--ios-easing);
            animation: root-ios-animation-hide-back .6s cubic-bezier(.36, .66, .04, 1);
            animation: root-ios-animation-hide-back .6s var(--ios-easing);
    }
@-webkit-keyframes root-ios-animation-show-back {
  from {
    opacity: .3;
    }

  to {
    opacity: 0;
    }
  }
@keyframes root-ios-animation-show-back {
  from {
    opacity: .3;
    }

  to {
    opacity: 0;
    }
  }
@-webkit-keyframes root-ios-animation-hide-forward {
  from {
    opacity: 0;
    }

  to {
    opacity: .3;
    }
  }
@keyframes root-ios-animation-hide-forward {
  from {
    opacity: 0;
    }

  to {
    opacity: .3;
    }
  }
@-webkit-keyframes root-ios-animation-hide-back {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    }

  to {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    }
  }
@keyframes root-ios-animation-hide-back {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    }

  to {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    }
  }
@-webkit-keyframes root-ios-animation-show-forward {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    }

  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    }
  }
@keyframes root-ios-animation-show-forward {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    }

  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    }
  }
/**
 * Android
 */
.Root--android {
  font-family: Roboto, Arial;
  font-family: Roboto, Arial;
  font-family: var(--font-android);
  }
.Root--android .Root__view--show-forward {
    -webkit-animation: root-android-animation-show-forward .3s cubic-bezier(.4, 0, .2, 1);
            animation: root-android-animation-show-forward .3s cubic-bezier(.4, 0, .2, 1);
    -webkit-animation: root-android-animation-show-forward .3s cubic-bezier(.4, 0, .2, 1);
    -webkit-animation: root-android-animation-show-forward .3s var(--android-easing);
            animation: root-android-animation-show-forward .3s cubic-bezier(.4, 0, .2, 1);
            animation: root-android-animation-show-forward .3s var(--android-easing);
    }
.Root--android .Root__view--hide-back {
    -webkit-animation: root-android-animation-hide-back .3s cubic-bezier(.4, 0, .2, 1);
            animation: root-android-animation-hide-back .3s cubic-bezier(.4, 0, .2, 1);
    -webkit-animation: root-android-animation-hide-back .3s cubic-bezier(.4, 0, .2, 1);
    -webkit-animation: root-android-animation-hide-back .3s var(--android-easing);
            animation: root-android-animation-hide-back .3s cubic-bezier(.4, 0, .2, 1);
            animation: root-android-animation-hide-back .3s var(--android-easing);
    }
@-webkit-keyframes root-android-animation-hide-back {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }

  to {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    opacity: 0;
    }
  }
@keyframes root-android-animation-hide-back {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }

  to {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    opacity: 0;
    }
  }
@-webkit-keyframes root-android-animation-show-forward {
  from {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    opacity: 0;
    }

  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }
  }
@keyframes root-android-animation-show-forward {
  from {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    opacity: 0;
    }

  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }
  }
.View--ios {
  font-family: -apple-system, Helvetica Neue, Arial;
  font-family: -apple-system, Helvetica Neue, Arial;
  font-family: var(--font-ios);
  }
.View--ios.View--header .Panel__in {
  padding-top: 64px;
  padding-top: calc(44px + 20px);
  padding-top: calc(44px + 20px);
  padding-top: calc(44px + var(--safe-area-inset-top));
  }
.View--ios .Panel__in {
  padding-bottom: 0px;
  padding-bottom: 0px;
  padding-bottom: var(--safe-area-inset-bottom);
  }
.View--ios.View--header .FixedLayout--top {
  top: 64px;
  top: calc(44px + 20px);
  top: calc(44px + 20px);
  top: calc(44px + var(--safe-area-inset-top));
  }
.View--ios .Panel__in {
  padding-bottom: 0px;
  padding-bottom: 0px;
  padding-bottom: var(--safe-area-inset-bottom);
  }
/**
 * Panel swipe back
 */
.View--ios .View__panel--swipe-back-prev {
  box-shadow: -2px 0 12px rgba(0, 0, 0, .3);
  }
.View--ios .View__panel--swipe-back-prev.View__panel--swipe-back-success {
  transition: -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: transform .3s cubic-bezier(.36, .66, .04, 1), -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: -webkit-transform .3s var(--ios-easing);
  transition: transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: transform .3s var(--ios-easing);
  transition: transform .3s cubic-bezier(.36, .66, .04, 1), -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: transform .3s var(--ios-easing), -webkit-transform .3s var(--ios-easing);
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  }
.View--ios .View__panel--swipe-back-next.View__panel--swipe-back-success {
  transition: -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: transform .3s cubic-bezier(.36, .66, .04, 1), -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: -webkit-transform .3s var(--ios-easing);
  transition: transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: transform .3s var(--ios-easing);
  transition: transform .3s cubic-bezier(.36, .66, .04, 1), -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: transform .3s var(--ios-easing), -webkit-transform .3s var(--ios-easing);
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  }
.View--ios .View__panel--swipe-back-prev.View__panel--swipe-back-failed {
  transition: -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: transform .3s cubic-bezier(.36, .66, .04, 1), -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: -webkit-transform .3s var(--ios-easing);
  transition: transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: transform .3s var(--ios-easing);
  transition: transform .3s cubic-bezier(.36, .66, .04, 1), -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: transform .3s var(--ios-easing), -webkit-transform .3s var(--ios-easing);
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  }
.View--ios .View__panel--swipe-back-next.View__panel--swipe-back-failed {
  transition: -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: transform .3s cubic-bezier(.36, .66, .04, 1), -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: -webkit-transform .3s var(--ios-easing);
  transition: transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: transform .3s var(--ios-easing);
  transition: transform .3s cubic-bezier(.36, .66, .04, 1), -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition: transform .3s var(--ios-easing), -webkit-transform .3s var(--ios-easing);
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
  }
/**
 * Panel transition
 */
.View--ios .View__panel--prev {
  -webkit-animation: animation-ios-prev-forward .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-prev-forward .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-prev-forward .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-prev-forward .6s var(--ios-easing);
          animation: animation-ios-prev-forward .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-prev-forward .6s var(--ios-easing);
  }
.View--ios .View__panel--next {
  -webkit-animation: animation-ios-next-back .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-next-back .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-next-back .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-next-back .6s var(--ios-easing);
          animation: animation-ios-next-back .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-next-back .6s var(--ios-easing);
  }
.View--ios .View__panel--prev ~ .View__panel--next {
  -webkit-animation: animation-ios-next-forward .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-next-forward .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-next-forward .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-next-forward .6s var(--ios-easing);
          animation: animation-ios-next-forward .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-next-forward .6s var(--ios-easing);
  }
.View--ios .View__panel--next ~ .View__panel--prev {
  -webkit-animation: animation-ios-prev-back .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-prev-back .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-prev-back .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-prev-back .6s var(--ios-easing);
          animation: animation-ios-prev-back .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-prev-back .6s var(--ios-easing);
  }
.View--ios .View__panel--prev .Panel__in::before,
.View--ios .View__panel--next .Panel__in::before {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background: #000;
  content: '';
  pointer-events: none;
  }
.View--ios .View__panel--prev .Panel__in::before {
  -webkit-animation: animation-ios-fade-in .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-fade-in .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-fade-in .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-fade-in .6s var(--ios-easing);
          animation: animation-ios-fade-in .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-fade-in .6s var(--ios-easing);
  }
.View--ios .View__panel--next .Panel__in::before {
  -webkit-animation: animation-ios-fade-out .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-fade-out .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-fade-out .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-fade-out .6s var(--ios-easing);
          animation: animation-ios-fade-out .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-fade-out .6s var(--ios-easing);
  }
.View--ios .View__panel--prev ~ .View__panel--next .Panel__in::before,
.View--ios .View__panel--next ~ .View__panel--prev .Panel__in::before {
  content: none;
  display: none;
  }
/**
 * Active panel
 */
.View--ios .View__panel--active .Panel__in::before {
  content: none;
  display: none;
  }
@-webkit-keyframes animation-ios-next-forward {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
    }

  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    }
  }
@keyframes animation-ios-next-forward {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
    }

  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    }
  }
@-webkit-keyframes animation-ios-next-back {
  from {
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0);
    }

  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    }
  }
@keyframes animation-ios-next-back {
  from {
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0);
    }

  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    }
  }
@-webkit-keyframes animation-ios-prev-forward {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    }

  to {
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0);
    }
  }
@keyframes animation-ios-prev-forward {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    }

  to {
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0);
    }
  }
@-webkit-keyframes animation-ios-prev-back {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    }

  to {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
    }
  }
@keyframes animation-ios-prev-back {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    }

  to {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
    }
  }
@-webkit-keyframes animation-ios-fade-in {
  from {
    opacity: 0;
    }

  to {
    opacity: .3;
    }
  }
@keyframes animation-ios-fade-in {
  from {
    opacity: 0;
    }

  to {
    opacity: .3;
    }
  }
@-webkit-keyframes animation-ios-fade-out {
  from {
    opacity: .3;
    }

  to {
    opacity: 0;
    }
  }
@keyframes animation-ios-fade-out {
  from {
    opacity: .3;
    }

  to {
    opacity: 0;
    }
  }
.View--android {
  font-family: Roboto, Arial;
  font-family: Roboto, Arial;
  font-family: var(--font-android);
  }
.View--android.View--header .Panel__in {
  padding-top: 56px;
  }
.View--android.View--header .FixedLayout--top {
  top: 56px;
  }
/**
 * Panel transition
 */
.View--android .View__panel--next ~ .View__panel--prev {
  -webkit-animation: animation-android-prev-back .3s cubic-bezier(.4, 0, .2, 1);
          animation: animation-android-prev-back .3s cubic-bezier(.4, 0, .2, 1);
  -webkit-animation: animation-android-prev-back .3s cubic-bezier(.4, 0, .2, 1);
  -webkit-animation: animation-android-prev-back .3s var(--android-easing);
          animation: animation-android-prev-back .3s cubic-bezier(.4, 0, .2, 1);
          animation: animation-android-prev-back .3s var(--android-easing);
  }
.View--android .View__panel--prev ~ .View__panel--next {
  -webkit-animation: animation-android-next-forward .3s cubic-bezier(.4, 0, .2, 1);
          animation: animation-android-next-forward .3s cubic-bezier(.4, 0, .2, 1);
  -webkit-animation: animation-android-next-forward .3s cubic-bezier(.4, 0, .2, 1);
  -webkit-animation: animation-android-next-forward .3s var(--android-easing);
          animation: animation-android-next-forward .3s cubic-bezier(.4, 0, .2, 1);
          animation: animation-android-next-forward .3s var(--android-easing);
  }
/**
 * Active panel
 */
.View--android .View__panel--active::before {
  content: none;
  }
@-webkit-keyframes animation-android-prev-back {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    }

  to {
    opacity: 0;
    -webkit-transform: translateY(80px);
            transform: translateY(80px);
    }
  }
@keyframes animation-android-prev-back {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    }

  to {
    opacity: 0;
    -webkit-transform: translateY(80px);
            transform: translateY(80px);
    }
  }
@-webkit-keyframes animation-android-next-forward {
  from {
    opacity: 0;
    -webkit-transform: translateY(80px);
            transform: translateY(80px);
    }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    }
  }
@keyframes animation-android-next-forward {
  from {
    opacity: 0;
    -webkit-transform: translateY(80px);
            transform: translateY(80px);
    }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    }
  }
.View {
  position: relative;
  height: 100%;
  width: 100%;
  word-wrap: break-word;
  }
.View--animated,
.View--swiping-back {
  overflow: hidden;
  }
.View__header {
    z-index: 2;
    position: fixed;
    width: 100%;
    }
.View--animated .View__panel,
  .View--animated .View__header,
  .View--swiping-back .View__panel,
  .View--swiping-back .View__header {
    pointer-events: none;
    }
.View__panels {
    height: 100%;
    width: 100%;
    }
.View__panel {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 1;
    }
.View__panel-in {
    height: 100%;
    }
.View--animated .View__panel,
  .View--swiping-back .View__panel {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    }
.View__popout {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    }
.Panel {
  position: relative;
  width: 100%;
  height: 100%;
  }
.Panel::after {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    content: '';
    }
.Panel--centered .Panel__in {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    }
.Panel__in {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
    z-index: 2;
    padding-bottom: 0px;
    padding-bottom: 0px;
    padding-bottom: var(--safe-area-inset-bottom);
    }
.Epic .Panel__in {
    padding-bottom: 48px;
    padding-bottom: 48px;
    padding-bottom: var(--tabbar_height);
    padding-bottom: calc(0px + 48px);
    padding-bottom: calc(0px + 48px);
    padding-bottom: calc(var(--safe-area-inset-bottom) + var(--tabbar_height));
    }
.Panel__in-before {
      height: 1px;
      margin-bottom: -1px;
      }
.Panel__in-after {
      height: 1px;
      margin-top: -1px;
      }
.Panel--tm-gray .Panel__in,
.Panel--tm-gray::after {
  background-color: #ebedf0;
  background-color: #ebedf0;
  background-color: var(--background_page);
  }
.Panel--tm-white .Panel__in,
.Panel--tm-white::after {
  background-color: #ffffff;
  background-color: #ffffff;
  background-color: var(--background_content);
  }
/**
 * Header iOS swipe back
 */
.PanelHeader__in--swipe-back-failed .PanelHeader__bg,
.PanelHeader__in--swipe-back-failed .PanelHeader__content,
.PanelHeader__in--swipe-back-failed .PanelHeader__left-in,
.PanelHeader__in--swipe-back-failed .PanelHeader__addon,
.PanelHeader__in--swipe-back-success .PanelHeader__bg,
.PanelHeader__in--swipe-back-success .PanelHeader__content,
.PanelHeader__in--swipe-back-success .PanelHeader__left-in,
.PanelHeader__in--swipe-back-success .PanelHeader__addon {
  transition:
      opacity .3s cubic-bezier(.36, .66, .04, 1),
      -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition:
      opacity .3s cubic-bezier(.36, .66, .04, 1),
      transform .3s cubic-bezier(.36, .66, .04, 1);
  transition:
      opacity .3s cubic-bezier(.36, .66, .04, 1),
      transform .3s cubic-bezier(.36, .66, .04, 1),
      -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition:
      opacity .3s cubic-bezier(.36, .66, .04, 1),
      -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition:
      opacity .3s var(--ios-easing),
      -webkit-transform .3s var(--ios-easing);
  transition:
      opacity .3s cubic-bezier(.36, .66, .04, 1),
      transform .3s cubic-bezier(.36, .66, .04, 1);
  transition:
      opacity .3s var(--ios-easing),
      transform .3s var(--ios-easing);
  transition:
      opacity .3s cubic-bezier(.36, .66, .04, 1),
      transform .3s cubic-bezier(.36, .66, .04, 1),
      -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
  transition:
      opacity .3s var(--ios-easing),
      transform .3s var(--ios-easing),
      -webkit-transform .3s var(--ios-easing);
  }
/*
  next failed
 */
.PanelHeader__in--swipe-back-next.PanelHeader__in--swipe-back-failed .PanelHeader__bg {}
.PanelHeader__in--swipe-back-next.PanelHeader__in--swipe-back-failed .PanelHeader__content,
.PanelHeader__in--swipe-back-next.PanelHeader__in--swipe-back-failed .PanelHeader__addon {
  opacity: 0;
  -webkit-transform: translate3d(-30vw, 0, 0);
          transform: translate3d(-30vw, 0, 0);
  }
.PanelHeader__in--swipe-back-next.PanelHeader__in--swipe-back-failed .PanelHeader__left-in,
.PanelHeader__in--swipe-back-next.PanelHeader__in--swipe-back-failed .PanelHeader__right {
  opacity: 0;
  }
/*
  next success
 */
.PanelHeader__in--swipe-back-next.PanelHeader__in--swipe-back-success .PanelHeader__content,
.PanelHeader__in--swipe-back-next.PanelHeader__in--swipe-back-success .PanelHeader__addon {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  }
.PanelHeader__in--swipe-back-next.PanelHeader__in--swipe-back-success .PanelHeader__left-in,
.PanelHeader__in--swipe-back-next.PanelHeader__in--swipe-back-success .PanelHeader__right {
  opacity: 1;
  }
/*
  prev failed
 */
.PanelHeader__in--swipe-back-prev.PanelHeader__in--swipe-back-failed .PanelHeader__content,
.PanelHeader__in--swipe-back-prev.PanelHeader__in--swipe-back-failed .PanelHeader__addon {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  opacity: 1;
  }
.PanelHeader__in--swipe-back-prev.PanelHeader__in--swipe-back-failed .PanelHeader__left-in,
.PanelHeader__in--swipe-back-prev.PanelHeader__in--swipe-back-failed .PanelHeader__right,
.PanelHeader__in--swipe-back-prev.PanelHeader__in--swipe-back-failed .PanelHeader__bg {
  opacity: 1;
  }
/*
  prev success
 */
.PanelHeader__in--swipe-back-prev.PanelHeader__in--swipe-back-success .PanelHeader__content,
.PanelHeader__in--swipe-back-prev.PanelHeader__in--swipe-back-success .PanelHeader__addon {
  -webkit-transform: translate3d(30vw, 0, 0);
          transform: translate3d(30vw, 0, 0);
  opacity: 0;
  }
.PanelHeader__in--swipe-back-prev.PanelHeader__in--swipe-back-success .PanelHeader__left-in,
.PanelHeader__in--swipe-back-prev.PanelHeader__in--swipe-back-success .PanelHeader__right,
.PanelHeader__in--swipe-back-prev.PanelHeader__in--swipe-back-success .PanelHeader__bg {
  opacity: 0;
  }
/**
 * Header transition
 */
.PanelHeader--ios .PanelHeader__in--prev,
.PanelHeader--ios .PanelHeader__in--next {
  overflow: hidden;
  }
.PanelHeader--ios .PanelHeader__in--next {
  z-index: 2;
  }
.PanelHeader--ios .PanelHeader__in--prev {
  z-index: 1;
  }
.PanelHeader--ios .PanelHeader__in--prev .PanelHeader__left-in,
.PanelHeader--ios .PanelHeader__in--prev .PanelHeader__right,
.PanelHeader--ios .PanelHeader__in--prev .PanelHeader-bg::after {
  -webkit-animation: animation-ios-header-fade-out .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-header-fade-out .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-header-fade-out .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-header-fade-out .6s var(--ios-easing);
          animation: animation-ios-header-fade-out .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-header-fade-out .6s var(--ios-easing);
  }
.PanelHeader--ios .PanelHeader__in--next .PanelHeader__left-in,
.PanelHeader--ios .PanelHeader__in--next .PanelHeader__right,
.PanelHeader--ios .PanelHeader__in--next .PanelHeader__bg {
  -webkit-animation: animation-ios-header-fade-in .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-header-fade-in .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-header-fade-in .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-header-fade-in .6s var(--ios-easing);
          animation: animation-ios-header-fade-in .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-header-fade-in .6s var(--ios-easing);
  }
.PanelHeader--ios .PanelHeader__in--prev .PanelHeader__content,
.PanelHeader--ios .PanelHeader__in--prev .PanelHeader__addon {
  -webkit-animation: animation-ios-header-prev-forward .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-header-prev-forward .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-header-prev-forward .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-header-prev-forward .6s var(--ios-easing);
          animation: animation-ios-header-prev-forward .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-header-prev-forward .6s var(--ios-easing);
  }
.PanelHeader--ios .PanelHeader__in--next .PanelHeader__content,
.PanelHeader--ios .PanelHeader__in--next .PanelHeader__addon {
  -webkit-animation: animation-ios-header-next-back .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-header-next-back .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-header-next-back .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-header-next-back .6s var(--ios-easing);
          animation: animation-ios-header-next-back .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-header-next-back .6s var(--ios-easing);
  }
.PanelHeader--ios .PanelHeader__in--prev ~ .PanelHeader__in--next .PanelHeader__content,
.PanelHeader--ios .PanelHeader__in--prev ~ .PanelHeader__in--next .PanelHeader__addon {
  -webkit-animation: animation-ios-header-next-forward .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-header-next-forward .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-header-next-forward .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-header-next-forward .6s var(--ios-easing);
          animation: animation-ios-header-next-forward .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-header-next-forward .6s var(--ios-easing);
  }
.PanelHeader--ios .PanelHeader__in--next ~ .PanelHeader__in--prev .PanelHeader__content,
.PanelHeader--ios .PanelHeader__in--next ~ .PanelHeader__in--prev .PanelHeader__addon {
  -webkit-animation: animation-ios-header-prev-back .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-header-prev-back .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-header-prev-back .6s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-header-prev-back .6s var(--ios-easing);
          animation: animation-ios-header-prev-back .6s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-header-prev-back .6s var(--ios-easing);
  }
@-webkit-keyframes animation-ios-header-next-back {
  from {
    opacity: 0;
    -webkit-transform: translateX(-30vw);
            transform: translateX(-30vw);
    }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    }
  }
@keyframes animation-ios-header-next-back {
  from {
    opacity: 0;
    -webkit-transform: translateX(-30vw);
            transform: translateX(-30vw);
    }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    }
  }
@-webkit-keyframes animation-ios-header-next-forward {
  from {
    opacity: 0;
    -webkit-transform: translateX(30vw);
            transform: translateX(30vw);
    }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    }
  }
@keyframes animation-ios-header-next-forward {
  from {
    opacity: 0;
    -webkit-transform: translateX(30vw);
            transform: translateX(30vw);
    }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    }
  }
@-webkit-keyframes animation-ios-header-prev-back {
  from {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    }

  to {
    opacity: 0;
    -webkit-transform: translateX(30vw);
            transform: translateX(30vw);
    }
  }
@keyframes animation-ios-header-prev-back {
  from {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    }

  to {
    opacity: 0;
    -webkit-transform: translateX(30vw);
            transform: translateX(30vw);
    }
  }
@-webkit-keyframes animation-ios-header-prev-forward {
  from {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    }

  to {
    opacity: 0;
    -webkit-transform: translateX(-30vw);
            transform: translateX(-30vw);
    }
  }
@keyframes animation-ios-header-prev-forward {
  from {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    }

  to {
    opacity: 0;
    -webkit-transform: translateX(-30vw);
            transform: translateX(-30vw);
    }
  }
@-webkit-keyframes animation-ios-header-fade-in {
  from {
    opacity: 0;
    }

  to {
    opacity: 1;
    }
  }
@keyframes animation-ios-header-fade-in {
  from {
    opacity: 0;
    }

  to {
    opacity: 1;
    }
  }
@-webkit-keyframes animation-ios-header-fade-out {
  from {
    opacity: 1;
    }

  to {
    opacity: 0;
    }
  }
@keyframes animation-ios-header-fade-out {
  from {
    opacity: 1;
    }

  to {
    opacity: 0;
    }
  }
.PanelHeader--android .PanelHeader__in--prev {
  -webkit-animation: animation-android-header-prev .3s cubic-bezier(.4, 0, .2, 1);
          animation: animation-android-header-prev .3s cubic-bezier(.4, 0, .2, 1);
  -webkit-animation: animation-android-header-prev .3s cubic-bezier(.4, 0, .2, 1);
  -webkit-animation: animation-android-header-prev .3s var(--android-easing);
          animation: animation-android-header-prev .3s cubic-bezier(.4, 0, .2, 1);
          animation: animation-android-header-prev .3s var(--android-easing);
  }
.PanelHeader--android .PanelHeader__in--next ~ .PanelHeader__in--prev {
  -webkit-animation: animation-android-header-prev-back .3s cubic-bezier(.4, 0, .2, 1);
          animation: animation-android-header-prev-back .3s cubic-bezier(.4, 0, .2, 1);
  -webkit-animation: animation-android-header-prev-back .3s cubic-bezier(.4, 0, .2, 1);
  -webkit-animation: animation-android-header-prev-back .3s var(--android-easing);
          animation: animation-android-header-prev-back .3s cubic-bezier(.4, 0, .2, 1);
          animation: animation-android-header-prev-back .3s var(--android-easing);
  }
.PanelHeader--android .PanelHeader__in--prev ~ .PanelHeader__in--next {
  -webkit-animation: animation-android-header-next-forward .3s cubic-bezier(.4, 0, .2, 1);
          animation: animation-android-header-next-forward .3s cubic-bezier(.4, 0, .2, 1);
  -webkit-animation: animation-android-header-next-forward .3s cubic-bezier(.4, 0, .2, 1);
  -webkit-animation: animation-android-header-next-forward .3s var(--android-easing);
          animation: animation-android-header-next-forward .3s cubic-bezier(.4, 0, .2, 1);
          animation: animation-android-header-next-forward .3s var(--android-easing);
  }
@-webkit-keyframes animation-android-header-prev {
  from {
    opacity: 1;
    }

  to {
    opacity: 0;
    }
  }
@keyframes animation-android-header-prev {
  from {
    opacity: 1;
    }

  to {
    opacity: 0;
    }
  }
@-webkit-keyframes animation-android-header-prev-back {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    }

  to {
    opacity: 0;
    -webkit-transform: translateY(80px);
            transform: translateY(80px);
    }
  }
@keyframes animation-android-header-prev-back {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    }

  to {
    opacity: 0;
    -webkit-transform: translateY(80px);
            transform: translateY(80px);
    }
  }
@-webkit-keyframes animation-android-header-next-forward {
  from {
    opacity: 0;
    -webkit-transform: translateY(80px);
            transform: translateY(80px);
    }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    }
  }
@keyframes animation-android-header-next-forward {
  from {
    opacity: 0;
    -webkit-transform: translateY(80px);
            transform: translateY(80px);
    }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    }
  }
.PanelHeader {
  position: relative;
  }
.PanelHeader__in {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    }
.PanelHeader__bg {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      }
.PanelHeader__container {
      display: flex;
      white-space: nowrap;
      align-items: center;
      position: relative;
      z-index: 2;
      }
.PanelHeader__left {
        flex-shrink: 0;
        display: flex;
        position: relative;
        z-index: 2;
        }
.PanelHeader__left-in {
          flex-shrink: 0;
          }
.PanelHeader-left-in {}
.PanelHeader__addon {
          flex-shrink: 0;
          }
.PanelHeader__content {
        overflow: hidden;
        text-overflow: ellipsis;
        }
.PanelHeader-content {
          overflow: hidden;
          text-overflow: ellipsis;
          }
.PanelHeader__right {
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
        position: relative;
        z-index: 2;
        }
.PanelHeader-right {
          box-sizing: border-box;
          text-align: right;
          justify-content: flex-end;
          display: flex;
          flex-shrink: 0;
          }
.PanelHeader-right--vkapps {
          width: 90px;
          }
.PanelHeader--ios {
  height: 44px;
  padding-top: 20px;
  padding-top: 20px;
  padding-top: var(--safe-area-inset-top);
  }
.PanelHeader--ios .PanelHeader__in {
    height: 44px;
    padding-top: 20px;
    padding-top: 20px;
    padding-top: var(--safe-area-inset-top);
    }
.PanelHeader--ios .PanelHeader__container {
      height: 44px;
      justify-content: space-between;
      align-items: flex-start;
      }
.PanelHeader--ios .PanelHeader__bg {}
.PanelHeader--ios .PanelHeader-bg {
          height: 44px;
          padding-top: 20px;
          padding-top: 20px;
          padding-top: var(--safe-area-inset-top);
          }
.PanelHeader--ios .PanelHeader-bg::after {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 1px;
          background: #d7d8d9;
          background: #d7d8d9;
          background: var(--separator_common);
          -webkit-transform-origin: center top;
                  transform-origin: center top;
          content: '';
          }
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
          .PanelHeader--ios .PanelHeader-bg::after {
            -webkit-transform: scaleY(.5);
                    transform: scaleY(.5);
            }
          }
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
          .PanelHeader--ios .PanelHeader-bg::after {
            -webkit-transform: scaleY(.33);
                    transform: scaleY(.33);
            }
          }
.PanelHeader--ios .PanelHeader-bg--no-shadow::after {
          content: none;
          }
.PanelHeader--ios .PanelHeader__left {
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-basis: 0;
        padding-right: 12px;
        height: 43px;
        }
.PanelHeader--ios .PanelHeader__left-in {
          box-sizing: border-box;
          height: 100%;
          }
.PanelHeader--ios .PanelHeader-left-in {
            font-size: 17px;
            height: 100%;
            }
.PanelHeader--ios .PanelHeader-left-in .HeaderButton .Icon--24 {
              padding-left: 12px;
              }
.PanelHeader--ios .PanelHeader-left-in .HeaderButton > *:not(.Icon) {
              padding-left: 16px;
              }
.PanelHeader--ios .PanelHeader__addon {
          height: 100%;
          }
.PanelHeader--ios .PanelHeader-addon:not(:empty) {
            padding-left: 4px;
            height: 100%;
            }
.PanelHeader--ios .PanelHeader__content {
        position: relative;
        z-index: 1;
        height: 43px;
        }
.PanelHeader--ios .PanelHeader-content {
          font-weight: 500;
          font-size: 17px;
          height: 100%;
          line-height: 43px;
          text-align: center;
          transition: color .6s cubic-bezier(.36, .66, .04, 1);
          transition: color .6s cubic-bezier(.36, .66, .04, 1);
          transition: color .6s var(--ios-easing);
          }
.PanelHeader--ios .PanelHeader__right {
        flex-grow: 1;
        flex-basis: 0;
        padding-left: 12px;
        height: 43px;
        }
.PanelHeader--ios .PanelHeader-right {
          font-size: 17px;
          height: 100%;
          }
.PanelHeader--ios .PanelHeader-right .HeaderButton:not(:first-child) {
            padding-left: 12px;
            }
.PanelHeader--ios .PanelHeader-right .HeaderButton .Icon--24 {
              padding-right: 12px;
              }
.PanelHeader--ios .PanelHeader-right .HeaderButton > *:not(.Icon) {
              padding-right: 16px;
              }
.PanelHeader--android {
  height: 56px;
  }
.PanelHeader--android .PanelHeader__in {
    height: 56px;
    }
.PanelHeader--android .PanelHeader__bg {
      height: 56px;
      }
.PanelHeader--android .PanelHeader-bg {
        height: 56px;
        box-shadow: 0 0 4px rgba(0, 0, 0, .08), 0 4px 4px rgba(0, 0, 0, .16);
        }
.PanelHeader--android .PanelHeader-bg--no-shadow {
        box-shadow: none;
        }
.PanelHeader--android .PanelHeader__container {
      height: 56px;
      }
.PanelHeader--android .PanelHeader__content {
        max-width: 100%;
        flex-grow: 2;
        height: 56px;
        }
.PanelHeader--android .PanelHeader-left-in {
        padding-right: 6px;
        }
.PanelHeader--android .PanelHeader-left-in:empty {
        padding-right: 12px;
        }
.PanelHeader--android .PanelHeader-right {
        padding-left: 6px;
        }
.PanelHeader--android .PanelHeader-right:not(:empty) {
          padding-right: 4px;
          }
.PanelHeader--android .PanelHeader-left-in:not(:empty) {
          padding-left: 4px;
          }
.PanelHeader--android .PanelHeader-content {
          font-size: 20px;
          line-height: 56px;
          height: 56px;
          font-weight: 500;
          display: flex;
          align-items: center;
        }
.PanelHeader--android .PanelHeader-content > *:not(.Search):not(.PanelHeaderContent) {
          padding: 0 6px;
          max-width: 100%;
          flex: 1 1;
          min-width: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
.PanelHeader--android .Search {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 3;
            }
/*
  Темы
 */
.PanelHeader-left-in--brand,
.PanelHeader-addon--brand,
.PanelHeader-right--brand {
  color: #ffffff;
  color: #ffffff;
  color: var(--header_tint);
  background: #5181b8;
  background: #5181b8;
  background: var(--header_background);
}
.PanelHeader-content--brand,
.PanelHeader-bg--brand {
  color: #ffffff;
  color: #ffffff;
  color: var(--header_text);
  background: #5181b8;
  background: #5181b8;
  background: var(--header_background);
  }
.PanelHeader-bg--light, /*TODO deprecated. Алиас для alternate. Будет удален в 3.0.0*/
.PanelHeader-bg--alternate,
.PanelHeader-left-in--light, /*TODO deprecated. Алиас для alternate. Будет удален в 3.0.0*/
.PanelHeader-addon--light, /*TODO deprecated. Алиас для alternate. Будет удален в 3.0.0*/
.PanelHeader-right--light, /*TODO deprecated. Алиас для alternate. Будет удален в 3.0.0*/
.PanelHeader-left-in--alternate,
.PanelHeader-addon--alternate,
.PanelHeader-right--alternate {
  color: #528bcc;
  color: #528bcc;
  color: var(--header_tint_alternate);
  background: #ffffff;
  background: #ffffff;
  background: var(--header_alternate_background);
  }
.PanelHeader-content--light, /*TODO deprecated. Алиас для alternate. Будет удален в 3.0.0*/
.PanelHeader-content--alternate {
  color: #000000;
  color: #000000;
  color: var(--text_primary);
  }
/*
  Прозрачность
 */
.PanelHeader-left-in--tp,
.PanelHeader-addon--tp,
.PanelHeader-right--tp,
.PanelHeader-bg--tp,
.PanelHeader-content--tp {
  background: transparent;
  }
.HeaderButton {
  -webkit-appearance: none;
          appearance: none;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  display: block;
  color: currentColor;
  padding: 0;
  margin: 0;
  font-size: 17px;
  }
.HeaderButton[disabled] {
  opacity: .6;
  }
.HeaderButton--ios {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  }
.HeaderButton--ios.HeaderButton--primary {
  font-weight: 600;
  }
.HeaderButton--ios > * {
    align-items: center;
    }
.HeaderButton--ios .Icon--chevron_back_28 {
    padding-left: 4px;
    }
.HeaderButton--ios.Tappable--active {
  opacity: .7;
  }
.HeaderButton--android {
  text-transform: uppercase;
  height: 48px;
  line-height: 48px;
  border-radius: 50%;
  font-weight: 500;
  font-size: 14px;
  padding: 0 12px;
  }
.HeaderButton--android .Icon--24 {
    padding: 12px 0;
    }
.HeaderButton--android .Tappable__waves {
    border-radius: 50%;
    }
.HeaderButton--android.Tappable--active {
  background-color: rgba(255, 255, 255, .1);
  }
.PanelHeaderContent {
  line-height: normal;
  line-height: initial;
  height: 100%;
  }
.PanelHeaderContent__in {
    flex: 1 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    }
.PanelHeaderContent__status {
    order: 1;
    }
.PanelHeaderContent__children {
    max-width: 100%;
    }
.PanelHeaderContent__children-in {
      flex-grow: 0;
      min-width: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      }
/*
  iOS
 */
.PanelHeaderContent--ios {

  }
.PanelHeaderContent--ios .PanelHeaderContent__in {
    align-items: center;
    justify-content: center;
    }
.PanelHeaderContent--ios .PanelHeaderContent__in.Tappable--active {
    opacity: .7;
    }
.PanelHeaderContent--ios .PanelHeaderContent__children {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      line-height: 20px;
      }
.PanelHeaderContent--ios .PanelHeaderContent__status ~ .PanelHeaderContent__children {
      font-size: 16px;
      line-height: 19px;
      }
.PanelHeaderContent--ios .PanelHeaderContent__aside {
        margin-left: 2px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        }
.PanelHeaderContent--ios .PanelHeaderContent__status {
      opacity: .6;
      font-size: 13px;
      }
/*
  Android
 */
.PanelHeaderContent--android {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  }
.PanelHeaderContent--android .PanelHeaderContent__before {
    padding-right: 6px;
    padding-left: 6px;
    }
.PanelHeaderContent--android .PanelHeaderContent__in {
    align-items: flex-start;
    justify-content: center;
    padding: 0 6px;
    }
.PanelHeaderContent--android .PanelHeaderContent__in.Tappable--active {
    background-color: rgba(255, 255, 255, .1);
    }
.PanelHeaderContent--android .PanelHeaderContent__children {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      }
.PanelHeaderContent--android .PanelHeaderContent__status ~ .PanelHeaderContent__children {
      font-size: 16px;
      line-height: 19px;
      }
.PanelHeaderContent--android .PanelHeaderContent__aside {
        margin-left: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        opacity: .8;
        }
.PanelHeaderContent--android .PanelHeaderContent__status {
      opacity: .6;
      font-size: 13px;
      line-height: 16px;
      }
.HeaderContext {}
.HeaderContext--opened,
  .HeaderContext--closing {
    width: 100%;
    height: 100%;
    }
.HeaderContext .HeaderContext__in {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 2;
    }
.HeaderContext .HeaderContext__fade {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background: rgba(0, 0, 0, .4);
    }
.HeaderContext--opened .HeaderContext__fade {
    -webkit-animation: animation-headercontext-fade-in .2s ease;
            animation: animation-headercontext-fade-in .2s ease;
    }
.HeaderContext--closing .HeaderContext__fade {
    -webkit-animation: animation-headercontext-fade-out .2s ease;
            animation: animation-headercontext-fade-out .2s ease;
    }
/*
 iOS
 */
.HeaderContext--ios {}
.HeaderContext--ios .HeaderContext__in {}
.HeaderContext--ios.HeaderContext--opened .HeaderContext__in {
    -webkit-animation: animation-headercontext-translate-in .2s ease;
            animation: animation-headercontext-translate-in .2s ease;
    }
.HeaderContext--ios.HeaderContext--closing .HeaderContext__in {
    -webkit-animation: animation-headercontext-translate-out .2s ease;
            animation: animation-headercontext-translate-out .2s ease;
    }
.HeaderContext--ios .List {
      background: #f5f5f5;
      background: #f5f5f5;
      background: var(--content_tint_background);
      }
/*
 Android
 */
.HeaderContext--android {}
.HeaderContext--android .HeaderContext__in {
  padding: 8px;
  box-sizing: border-box;
  }
.HeaderContext--android.HeaderContext--opened .HeaderContext__in {
  -webkit-animation: animation-headercontext-translate-in .2s ease;
          animation: animation-headercontext-translate-in .2s ease;
  }
.HeaderContext--android.HeaderContext--closing .HeaderContext__in {
  -webkit-animation: animation-headercontext-translate-out .2s ease;
          animation: animation-headercontext-translate-out .2s ease;
  }
.HeaderContext--android .List {
  overflow: hidden;
  border-radius: 12px;
  padding: 8px 0;
  }
/*
  Animations
 */
@-webkit-keyframes animation-headercontext-translate-in {
  from { -webkit-transform: translateY(-100%); transform: translateY(-100%); }
  to { -webkit-transform: translateY(0); transform: translateY(0); }
  }
@keyframes animation-headercontext-translate-in {
  from { -webkit-transform: translateY(-100%); transform: translateY(-100%); }
  to { -webkit-transform: translateY(0); transform: translateY(0); }
  }
@-webkit-keyframes animation-headercontext-translate-out {
  from { -webkit-transform: translateY(0); transform: translateY(0); }
  to { -webkit-transform: translateY(-100%); transform: translateY(-100%); }
  }
@keyframes animation-headercontext-translate-out {
  from { -webkit-transform: translateY(0); transform: translateY(0); }
  to { -webkit-transform: translateY(-100%); transform: translateY(-100%); }
  }
@-webkit-keyframes animation-headercontext-fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
  }
@keyframes animation-headercontext-fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
  }
@-webkit-keyframes animation-headercontext-fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
  }
@keyframes animation-headercontext-fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
  }
.Epic {
  width: 100%;
  height: 100%;
  }
.Epic--ios {
    font-family: -apple-system, Helvetica Neue, Arial;
    font-family: -apple-system, Helvetica Neue, Arial;
    font-family: var(--font-ios);
  }
.Epic--android {
    font-family: Roboto, Arial;
    font-family: Roboto, Arial;
    font-family: var(--font-android);
  }
.Tabbar {
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 48px;
  height: 48px;
  height: var(--tabbar_height);
  display: flex;
  align-items: stretch;
  justify-content: center;
  box-sizing: content-box;
  background: #ffffff;
  background: #ffffff;
  background: var(--header_alternate_background);
  padding-bottom: 0px;
  padding-bottom: 0px;
  padding-bottom: var(--safe-area-inset-bottom);
  }
.Tabbar--ios.Tabbar--shadow::before {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #d7d8d9;
  background: #d7d8d9;
  background: var(--separator_common);
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  content: '';
  }
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .Tabbar--ios::before {
    -webkit-transform: scaleY(.5);
            transform: scaleY(.5);
    }
  }
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
  .Tabbar--ios::before {
    -webkit-transform: scaleY(.33);
            transform: scaleY(.33);
    }
  }
.Tabbar--android.Tabbar--shadow {
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, .06), 0 0 2px 0 rgba(0, 0, 0, .08);
  }
.TabbarItem {
  flex-shrink: 0;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  }
.TabbarItem {
  color: #99a2ad;
  color: #99a2ad;
  color: var(--tabbar_inactive_icon);
  }
.TabbarItem--selected {
  color: #2975cc;
  color: #2975cc;
  color: var(--tabbar_active_icon);
  }
.TabbarItem__in {
    align-self: center;
    display: flex;
    }
.Tabbar--l-vertical .TabbarItem__in {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }
.Tabbar--l-horizontal .TabbarItem__in {
    flex-direction: row;
    align-items: center;
    }
.TabbarItem__icon {
      position: relative;
      }
.TabbarItem__label {
      position: absolute;
      top: 0;
      left: calc(100% - 12px);
      background: #e64646;
      background: #e64646;
      background: var(--destructive);
      font-size: 12px;
      color: #fff;
      color: #fff;
      color: var(--white);
      line-height: 14px;
      border-radius: 9px;
      min-width: 18px;
      text-align: center;
      padding: 2px 4px;
      box-sizing: border-box;
      }
.Tabbar--l-vertical .TabbarItem__text {
      font-size: 10px;
      line-height: 12px;
      font-weight: 500;
      margin-top: 2px;
      }
.Tabbar--l-horizontal .TabbarItem__text {
      font-size: 13px;
      font-weight: 500;
      margin-left: 8px;
      }
.FixedLayout {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  z-index: 3;
  }
.FixedLayout--top {
  width: 100%;
  top: 0;
  }
.FixedLayout--bottom {
  width: 100%;
  bottom: 0;
  padding-bottom: 0px;
  padding-bottom: 0px;
  padding-bottom: var(--safe-area-inset-bottom);
  }
.Epic .FixedLayout--bottom {
    padding-bottom: calc(48px + 0px);
    padding-bottom: calc(48px + 0px);
    padding-bottom: calc(var(--tabbar_height) + var(--safe-area-inset-bottom));
    }
.HorizontalScroll {
  position: relative;
  overflow: hidden;
  }
.HorizontalScroll__in {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 20px;
    margin-bottom: -20px;
    }
.HorizontalScroll__in::-webkit-scrollbar {
    display: none;
  }
.InnerScroll {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 1px 0;
  box-sizing: border-box;
  }
/* Popouts */
.PopoutWrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  left: 0;
  top: 0;
  }
.PopoutWrapper--closing .PopoutWrapper__mask {
    opacity: 0;
    }
.PopoutWrapper__mask {
    opacity: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .4);
    -webkit-animation: animation-full-fade-in .3s ease;
            animation: animation-full-fade-in .3s ease;
    -webkit-user-select: none;
            user-select: none;
    }
.PopoutWrapper__container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    box-sizing: border-box;
    z-index: 2;
    }
.PopoutWrapper--v-center .PopoutWrapper__container {
    align-items: center;
    }
.PopoutWrapper--v-bottom .PopoutWrapper__container {
    align-items: flex-end;
    }
.PopoutWrapper--v-top .PopoutWrapper__container {
    align-items: flex-start;
    }
.PopoutWrapper--h-center .PopoutWrapper__container {
    justify-content: center;
    }
.PopoutWrapper--h-left .PopoutWrapper__container {
    justify-content: flex-start;
    }
.PopoutWrapper--h-right .PopoutWrapper__container {
    justify-content: flex-end;
    }
/**
 * iOS version
 */
.PopoutWrapper--ios.PopoutWrapper--closing .PopoutWrapper__mask {
  transition: opacity .3s cubic-bezier(.36, .66, .04, 1);
  transition: opacity .3s cubic-bezier(.36, .66, .04, 1);
  transition: opacity .3s var(--ios-easing);
  }
/**
 * Android version
 */
.PopoutWrapper--android.PopoutWrapper--closing .PopoutWrapper__mask {
  transition: opacity .2s cubic-bezier(.4, 0, .2, 1);
  transition: opacity .2s cubic-bezier(.4, 0, .2, 1);
  transition: opacity .2s var(--android-easing);
  }
@-webkit-keyframes animation-full-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes animation-full-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.ActionSheet {
  position: relative;
  overflow: hidden;
  }
.ActionSheet--android::before,
  .ActionSheet--ios .ActionSheet__header::before,
  .ActionSheet--ios .ActionSheetItem::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    background-color: #ffffff;
    background-color: var(--modal_card_background);
    z-index: 1;
    content: '';
    transition: opacity .15s ease-out, background-color .15s ease-out;
    }
.ActionSheetItem {
  position: relative;
  z-index: 2;
  overflow: hidden;
  }
/**
 * iOS version
 */
.ActionSheet--ios {
  width: 100%;
  -webkit-animation: animation-ios-actionsheet-intro .3s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-actionsheet-intro .3s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-actionsheet-intro .3s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-actionsheet-intro .3s var(--ios-easing);
          animation: animation-ios-actionsheet-intro .3s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-actionsheet-intro .3s var(--ios-easing);
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  }
.ActionSheet--ios.ActionSheet--closing {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    transition: -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
    transition: transform .3s cubic-bezier(.36, .66, .04, 1);
    transition: transform .3s cubic-bezier(.36, .66, .04, 1), -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
    transition: -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
    transition: -webkit-transform .3s var(--ios-easing);
    transition: transform .3s cubic-bezier(.36, .66, .04, 1);
    transition: transform .3s var(--ios-easing);
    transition: transform .3s cubic-bezier(.36, .66, .04, 1), -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
    transition: transform .3s var(--ios-easing), -webkit-transform .3s var(--ios-easing);
    }
.ActionSheet--ios .ActionSheet__header {
    box-sizing: border-box;
    padding: 14px 10px 13px;
    position: relative;
    overflow: hidden;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    }
.ActionSheet--ios .ActionSheet__header:empty {
    display: none;
    }
.ActionSheet--ios .ActionSheet__title {
      text-align: center;
      font-size: 13px;
      font-weight: 600;
      line-height: 1.38;
      color: #909499;
      color: #909499;
      color: var(--text_secondary);
      position: relative;
      z-index: 2;
      }
.ActionSheet--ios .ActionSheet__title + .ActionSheet__text {
      margin-top: 10px;
      }
.ActionSheet--ios .ActionSheet__text {
      text-align: center;
      font-size: 12px;
      line-height: 1.2;
      color: #909499;
      color: #909499;
      color: var(--text_secondary);
      position: relative;
      z-index: 2;
      }
.ActionSheet--ios .ActionSheetItem {
  padding: 16px 10px 17px;
  text-align: center;
  font-size: 20px;
  position: relative;
  overflow: hidden;
  }
.ActionSheet--ios .ActionSheetItem.Tappable--active::before {
    background: #d7d8d9;
    background: #d7d8d9;
    background: var(--separator_common);
    opacity: 1;
    transition: none;
    }
.ActionSheet--ios .ActionSheetItem__in {
    position: relative;
    z-index: 2;
    }
.ActionSheet--ios .ActionSheet__header:empty + .ActionSheetItem {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  }
.ActionSheet--ios .ActionSheetItem:last-of-type {
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  }
.ActionSheet--ios .ActionSheetItem--default {
  color: #2787f5;
  color: #2787f5;
  color: var(--action_sheet_action_foreground);
  }
.ActionSheet--ios .ActionSheetItem--destructive {
  color: #e64646;
  color: #e64646;
  color: var(--destructive);
  }
.ActionSheet--ios .ActionSheetItem--cancel {
  display: block;
  width: auto;
  margin-top: 7px;
  margin-bottom: 0px;
  margin-bottom: 0px;
  margin-bottom: var(--safe-area-inset-bottom);
  color: #2787f5;
  color: #2787f5;
  color: var(--action_sheet_action_foreground);
  font-weight: 600;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  order: 1;
  }
.ActionSheet--ios div.ActionSheetItem::after,
.ActionSheet--ios .ActionSheet__header::after {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1;
  height: 1px;
  content: '';
  bottom: 0;
  background: rgba(0, 0, 0, 0.12);
  background: rgba(0, 0, 0, 0.12);
  background: var(--action_sheet_separator);
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  }
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .ActionSheet--ios div.ActionSheetItem::after,
  .ActionSheet--ios .ActionSheet__header::after {
    -webkit-transform: scaleY(.5);
            transform: scaleY(.5);
    }
  }
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
  .ActionSheet--ios div.ActionSheetItem::after,
  .ActionSheet--ios .ActionSheet__header::after {
    -webkit-transform: scaleY(.33);
            transform: scaleY(.33);
    }
  }
.ActionSheet--ios div.ActionSheetItem:last-of-type::after,
.ActionSheet--ios .ActionSheet__header:empty {
  content: none;
  }
/**
 * Android version
 */
.ActionSheet--android {
  min-width: 280px;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, .16), 0 0 8px 0 rgba(0, 0, 0, .12);
  -webkit-animation: animation-android-actionsheet-intro .2s ease;
          animation: animation-android-actionsheet-intro .2s ease;
  padding: 8px 0;
  border-radius: 12px;
}
.ActionSheet--android.ActionSheet--closing {
    -webkit-transform: scale(.95);
            transform: scale(.95);
    opacity: 0;
    transition: opacity .2s cubic-bezier(.4, 0, .2, 1), -webkit-transform .2s cubic-bezier(.4, 0, .2, 1);
    transition: transform .2s cubic-bezier(.4, 0, .2, 1), opacity .2s cubic-bezier(.4, 0, .2, 1);
    transition: transform .2s cubic-bezier(.4, 0, .2, 1), opacity .2s cubic-bezier(.4, 0, .2, 1), -webkit-transform .2s cubic-bezier(.4, 0, .2, 1);
    transition: opacity .2s cubic-bezier(.4, 0, .2, 1), -webkit-transform .2s cubic-bezier(.4, 0, .2, 1);
    transition: opacity .2s var(--android-easing), -webkit-transform .2s var(--android-easing);
    transition: transform .2s cubic-bezier(.4, 0, .2, 1), opacity .2s cubic-bezier(.4, 0, .2, 1);
    transition: transform .2s var(--android-easing), opacity .2s var(--android-easing);
    transition: transform .2s cubic-bezier(.4, 0, .2, 1), opacity .2s cubic-bezier(.4, 0, .2, 1), -webkit-transform .2s cubic-bezier(.4, 0, .2, 1);
    transition: transform .2s var(--android-easing), opacity .2s var(--android-easing), -webkit-transform .2s var(--android-easing);
    }
.ActionSheet--android .ActionSheetItem {
  padding: 14px 24px;
  line-height: 1.25;
  font-size: 16px;
  }
.ActionSheet--android .ActionSheetItem.Tappable--active {
  background-color: rgba(0, 0, 0, 0.12);
  background-color: rgba(0, 0, 0, 0.12);
  background-color: var(--action_sheet_separator);
  }
.ActionSheet--android .ActionSheetItem--default {
  color: #000000;
  color: #000000;
  color: var(--text_primary);
  }
.ActionSheet--android .ActionSheetItem--destructive {
  color: #e64646;
  color: #e64646;
  color: var(--destructive);
  }
/**
* Animations
*/
@-webkit-keyframes animation-ios-actionsheet-intro {
  from {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    }

  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    }
  }
@keyframes animation-ios-actionsheet-intro {
  from {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    }

  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    }
  }
@-webkit-keyframes animation-android-actionsheet-intro {
  from {
    opacity: 0;
    -webkit-transform: scale(.95);
            transform: scale(.95);
    }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    }
  }
@keyframes animation-android-actionsheet-intro {
  from {
    opacity: 0;
    -webkit-transform: scale(.95);
            transform: scale(.95);
    }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    }
  }
.Alert {
  -webkit-user-select: none;
          user-select: none;
  color: #000000;
  color: #000000;
  color: var(--text_primary);
  position: relative;
  overflow: hidden;
  }
.Alert::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    background-color: #ffffff;
    background-color: var(--modal_card_background);
    z-index: 1;
    content: '';
    }
.Alert__content {
    position: relative;
    z-index: 2;
    }
.Alert__btn {
    white-space: nowrap;
    background: transparent;
    }
.Alert__footer {
    display: flex;
    max-width: 100%;
    position: relative;
    z-index: 2;
    }
/**
 * iOS version
 */
.Alert--ios {
  width: 270px;
  flex: 0 0 auto;
  border-radius: 12px;
  -webkit-animation: animation-ios-alert-intro .3s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-alert-intro .3s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-alert-intro .3s cubic-bezier(.36, .66, .04, 1);
  -webkit-animation: animation-ios-alert-intro .3s var(--ios-easing);
          animation: animation-ios-alert-intro .3s cubic-bezier(.36, .66, .04, 1);
          animation: animation-ios-alert-intro .3s var(--ios-easing);
  }
.Alert--ios.Alert--closing {
  opacity: 0;
  transition: opacity .2s cubic-bezier(.36, .66, .04, 1);
  transition: opacity .2s cubic-bezier(.36, .66, .04, 1);
  transition: opacity .2s var(--ios-easing);
  }
/**
   * Content container
   */
.Alert--ios .Alert__content {
    padding: 19px 16px 20px;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    }
.Alert--ios .Alert__content::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.12);
    background: rgba(0, 0, 0, 0.12);
    background: var(--action_sheet_separator);
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    }
.Alert--ios .Alert__content h2 {
      margin: 0 0 7px;
      font-weight: bold;
      font-size: 17px;
      line-height: 22px;
      }
.Alert--ios .Alert__content p {
      margin: 0;
      }
/**
   * Footer
   */
.Alert--ios.Alert--v .Alert__footer {
    flex-direction: column;
    }
/**
     * iOS Alert button
     */
.Alert--ios .Alert__btn {
      position: relative;
      font-size: 17px;
      line-height: 44px;
      color: #528bcc;
      color: #528bcc;
      color: var(--accent);
      border: 0;
      padding: 0 6px;
      margin: 0;
      height: 44px;
      display: block;
      outline: none;
      overflow: hidden;
      text-overflow: ellipsis;
      }
.Alert--ios .Alert__btn:active {
      z-index: 1;
      }
.Alert--ios .Alert__btn::after {
      content: '';
      position: absolute;
      background: rgba(0, 0, 0, 0.12);
      background: rgba(0, 0, 0, 0.12);
      background: var(--action_sheet_separator);
      }
.Alert--ios.Alert--h .Alert__btn::after {
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      -webkit-transform-origin: right center;
              transform-origin: right center;
      }
.Alert--ios.Alert--h .Alert__btn:last-child::after {
      content: none;
      }
.Alert--ios.Alert--h .Alert__btn {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      }
.Alert--ios.Alert--h .Alert__btn:first-child {
      border-bottom-left-radius: 12px;
      }
.Alert--ios.Alert--h .Alert__btn:last-child {
      border-bottom-right-radius: 12px;
      }
.Alert--ios.Alert--v .Alert__btn::after {
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      -webkit-transform-origin: center bottom;
              transform-origin: center bottom;
      }
.Alert--ios.Alert--v .Alert__btn:last-child::after {
      content: none;
      }
.Alert--ios.Alert--v .Alert__btn:last-child {
      border-radius: 0 0 12px 12px;
      }
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .Alert--ios .Alert__content::after,
  .Alert--ios.Alert--v .Alert__btn::after {
    -webkit-transform: scaleY(.5);
            transform: scaleY(.5);
    }

    .Alert--ios.Alert--h .Alert__btn::after {
      -webkit-transform: scaleX(.5);
              transform: scaleX(.5);
      }
  }
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
  .Alert--ios .Alert__content::after,
  .Alert--ios.Alert--v .Alert__btn::after {
    -webkit-transform: scaleY(.33);
            transform: scaleY(.33);
    }

  .Alert--ios.Alert--h .Alert__btn::after {
    -webkit-transform: scaleX(.33);
            transform: scaleX(.33);
    }
  }
.Alert--ios .Alert__btn--cancel {
    font-weight: 600;
    }
.Alert--ios .Alert__btn--destructive {
    color: #e64646;
    color: #e64646;
    color: var(--destructive);
    }
/**
 * Android version
 */
.Alert--android {
  min-width: 280px;
  margin: auto 24px;
  box-shadow: 0 20px 35px 0 rgba(0, 0, 0, .3), 0 0 35px 0 rgba(0, 0, 0, .22);
  border-radius: 12px;
  -webkit-animation: animation-android-alert-intro .2s ease;
          animation: animation-android-alert-intro .2s ease;
  }
.Alert--android.Alert--closing {
  -webkit-transform: scale(.95);
          transform: scale(.95);
  opacity: 0;
  transition: opacity .2s cubic-bezier(.4, 0, .2, 1), -webkit-transform .2s cubic-bezier(.4, 0, .2, 1);
  transition: transform .2s cubic-bezier(.4, 0, .2, 1), opacity .2s cubic-bezier(.4, 0, .2, 1);
  transition: transform .2s cubic-bezier(.4, 0, .2, 1), opacity .2s cubic-bezier(.4, 0, .2, 1), -webkit-transform .2s cubic-bezier(.4, 0, .2, 1);
  transition: opacity .2s cubic-bezier(.4, 0, .2, 1), -webkit-transform .2s cubic-bezier(.4, 0, .2, 1);
  transition: opacity .2s var(--android-easing), -webkit-transform .2s var(--android-easing);
  transition: transform .2s cubic-bezier(.4, 0, .2, 1), opacity .2s cubic-bezier(.4, 0, .2, 1);
  transition: transform .2s var(--android-easing), opacity .2s var(--android-easing);
  transition: transform .2s cubic-bezier(.4, 0, .2, 1), opacity .2s cubic-bezier(.4, 0, .2, 1), -webkit-transform .2s cubic-bezier(.4, 0, .2, 1);
  transition: transform .2s var(--android-easing), opacity .2s var(--android-easing), -webkit-transform .2s var(--android-easing);
  }
/**
   * Content container
   */
.Alert--android .Alert__content {
    padding: 20px 24px 8px;
    font-size: 16px;
    line-height: 24px;
    }
.Alert--android .Alert__content h2 {
      margin: 0 0 8px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      }
.Alert--android .Alert__content p {
      margin: 0;
      }
.Alert--android .Alert__content p + p {
      margin: 24px 0 0;
      }
/**
   * Footer
   */
.Alert--android .Alert__footer {
    padding: 12px 16px 16px;
    display: flex;
    justify-content: flex-end;
    flex-flow: wrap;
    }
.Alert--android.Alert--v .Alert__footer {
    flex-direction: column;
    align-items: flex-end;
    }
.Alert--android .Alert__btn {
      margin: 4px 0 0 8px;
      border: 0;
      flex: 0 1 auto;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      padding: 8px 16px;
      border-radius: 8px;
      color: #528bcc;
      color: #528bcc;
      color: var(--accent);
      outline: none;
      overflow: hidden;
      text-overflow: ellipsis;
      }
.Alert--android .Alert__btn--destructive {
      color: #e64646;
      color: #e64646;
      color: var(--destructive);
      }
/**
 * Animations
 */
@-webkit-keyframes animation-ios-alert-intro {
  from {
    opacity: 0;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    }
  }
@keyframes animation-ios-alert-intro {
  from {
    opacity: 0;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    }
  }
@-webkit-keyframes animation-android-alert-intro {
  from {
    opacity: 0;
    -webkit-transform: scale(.95);
            transform: scale(.95);
    }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    }
  }
@keyframes animation-android-alert-intro {
  from {
    opacity: 0;
    -webkit-transform: scale(.95);
            transform: scale(.95);
    }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    }
  }
.ScreenSpinner .PopoutWrapper__mask {
  background: none;
  }
.ScreenSpinner {
  -webkit-animation: screen-spinner-intro .3s ease;
          animation: screen-spinner-intro .3s ease;
  }
.ScreenSpinner--ios .ScreenSpinner__container {
  width: 88px;
  height: 88px;
  background: #ebedf0;
  background: #ebedf0;
  background: var(--overlay_status_background);
  border-radius: 10px;
  }
.ScreenSpinner--ios .ScreenSpinner__container .Spinner {
  color: #818c99;
  color: #818c99;
  color: var(--overlay_status_icon);
  }
.ScreenSpinner--android .ScreenSpinner__container {
  width: 88px;
  height: 88px;
  background: #f9f9f9;
  background: #f9f9f9;
  background: var(--tabbar_background);
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, .08), 0 0 8px 0 rgba(0, 0, 0, .08);
  border-radius: 8px;
  }
.ScreenSpinner--android .ScreenSpinner__container .Spinner {
  color: #99a2ad;
  color: #99a2ad;
  color: var(--icon_outline_secondary);
  }
@-webkit-keyframes screen-spinner-intro {
  from {
    opacity: 0;
    }

  to {
    opacity: 1;
    }
  }
@keyframes screen-spinner-intro {
  from {
    opacity: 0;
    }

  to {
    opacity: 1;
    }
  }
/* Modals */
.ModalRoot {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}
.ModalRoot__mask {
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .4);
  -webkit-user-select: none;
          user-select: none;
  transition: opacity 167ms ease-out;
  -webkit-animation: animation-fadeIn .32s ease;
          animation: animation-fadeIn .32s ease;
}
.ModalRoot--touched .ModalRoot__mask {
  transition: none;
}
.ModalRoot__viewport {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
.ModalRoot--ios .ModalRoot__viewport {
  top: 20px;
  top: 20px;
  top: var(--safe-area-inset-top);
}
.ModalRoot--vkapps.ModalRoot--android .ModalRoot__viewport {
  top: 56px;
  top: 56px;
  top: var(--panelheader_height_android);
}
.ModalRoot--vkapps.ModalRoot--ios .ModalRoot__viewport {
  top: calc(20px + 44px);
  top: calc(20px + 44px);
  top: calc(var(--safe-area-inset-top) + var(--panelheader_height_ios));
}
.ModalRoot__modal {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 1;
}
.ModalCard {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  z-index: 1;
}
.ModalCard__in {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 480px;
  padding: 8px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  box-sizing: border-box;
}
.ModalCard--ios .ModalCard__in {
  transition: -webkit-transform 340ms cubic-bezier(.36, .66, .04, 1);
  transition: transform 340ms cubic-bezier(.36, .66, .04, 1);
  transition: transform 340ms cubic-bezier(.36, .66, .04, 1), -webkit-transform 340ms cubic-bezier(.36, .66, .04, 1);
  transition: -webkit-transform 340ms cubic-bezier(.36, .66, .04, 1);
  transition: -webkit-transform 340ms var(--ios-easing);
  transition: transform 340ms cubic-bezier(.36, .66, .04, 1);
  transition: transform 340ms var(--ios-easing);
  transition: transform 340ms cubic-bezier(.36, .66, .04, 1), -webkit-transform 340ms cubic-bezier(.36, .66, .04, 1);
  transition: transform 340ms var(--ios-easing), -webkit-transform 340ms var(--ios-easing);
}
.ModalCard--android .ModalCard__in {
  transition: -webkit-transform 340ms cubic-bezier(.4, 0, .2, 1);
  transition: transform 340ms cubic-bezier(.4, 0, .2, 1);
  transition: transform 340ms cubic-bezier(.4, 0, .2, 1), -webkit-transform 340ms cubic-bezier(.4, 0, .2, 1);
  transition: -webkit-transform 340ms cubic-bezier(.4, 0, .2, 1);
  transition: -webkit-transform 340ms var(--android-easing);
  transition: transform 340ms cubic-bezier(.4, 0, .2, 1);
  transition: transform 340ms var(--android-easing);
  transition: transform 340ms cubic-bezier(.4, 0, .2, 1), -webkit-transform 340ms cubic-bezier(.4, 0, .2, 1);
  transition: transform 340ms var(--android-easing), -webkit-transform 340ms var(--android-easing);
}
.ModalRoot--touched .ModalCard__in {
  transition: none;
}
.ModalCard__container {
  box-sizing: border-box;
  position: relative;
  background: #ffffff;
  background: #ffffff;
  background: var(--modal_card_background);
  border-radius: 18px;
  padding: 16px;
  margin-bottom: 0px;
  margin-bottom: 0px;
  margin-bottom: var(--safe-area-inset-bottom);
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: initial;
}
.ModalCard--android .ModalCard__container {
  border-radius: 12px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .16), 0 0 4px 0 rgba(0, 0, 0, .08);
}
.ModalCard__dismiss {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 48px;
  height: 48px;
  color: #99a2ad;
  color: #99a2ad;
  color: var(--placeholder_icon_foreground_primary);
  justify-content: center;
}
.ModalCard__title,
.ModalCard__caption {
  text-align: center;
  margin: 0;
  padding: 0 8px;
}
.ModalCard__title {
  color: #000000;
  color: #000000;
  color: var(--text_primary);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: .3px;
  text-align: center;
}
.ModalCard--ios .ModalCard__title:first-child {
  padding-left: 36px;
  padding-right: 36px;
}
.ModalCard__caption {
  color: #909499;
  color: #909499;
  color: var(--text_secondary);
  font-size: 14px;
  line-height: 1.3;
}
.ModalCard__title + .ModalCard__caption {
  margin-top: 8px;
}
.ModalCard__title + .FormField {
  margin-top: 16px;
}
.ModalCard .UsersStack {
  margin-top: 12px;
}
.ModalCard__icon {
  margin: 8px auto 16px;
  color: #528bcc;
  color: #528bcc;
  color: var(--accent);
}
.ModalCard__actions {
  display: flex;
  margin-left: -6px;
  margin-right: -6px;
  margin-top: 16px;
}
.ModalCard .UsersStack + .ModalCard__actions {
  margin-top: 24px;
}
.ModalCard__title + .ModalCard__actions,
.ModalCard__caption + .ModalCard__actions {
  margin-top: 32px;
}
.ModalCard__actions .Button {
  flex: 1 1;
  margin-left: 6px;
  margin-right: 6px;
}
.ModalCard__actions--v {
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
}
.ModalCard__actions--v .Button {
  margin-left: 0;
  margin-right: 0;
}
.ModalCard__actions--v .Button + .Button {
  margin-top: 12px;
}
.ModalPage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  box-sizing: border-box;
  z-index: 1;
  pointer-events: none;
}
.ModalPage__in-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  pointer-events: initial;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.ModalPage--ios .ModalPage__in-wrap {
  transition: -webkit-transform 400ms cubic-bezier(.36, .66, .04, 1);
  transition: transform 400ms cubic-bezier(.36, .66, .04, 1);
  transition: transform 400ms cubic-bezier(.36, .66, .04, 1), -webkit-transform 400ms cubic-bezier(.36, .66, .04, 1);
  transition: -webkit-transform 400ms cubic-bezier(.36, .66, .04, 1);
  transition: -webkit-transform 400ms var(--ios-easing);
  transition: transform 400ms cubic-bezier(.36, .66, .04, 1);
  transition: transform 400ms var(--ios-easing);
  transition: transform 400ms cubic-bezier(.36, .66, .04, 1), -webkit-transform 400ms cubic-bezier(.36, .66, .04, 1);
  transition: transform 400ms var(--ios-easing), -webkit-transform 400ms var(--ios-easing);
}
.ModalPage--android .ModalPage__in-wrap {
  transition: -webkit-transform 320ms cubic-bezier(.4, 0, .2, 1);
  transition: transform 320ms cubic-bezier(.4, 0, .2, 1);
  transition: transform 320ms cubic-bezier(.4, 0, .2, 1), -webkit-transform 320ms cubic-bezier(.4, 0, .2, 1);
  transition: -webkit-transform 320ms cubic-bezier(.4, 0, .2, 1);
  transition: -webkit-transform 320ms var(--android-easing);
  transition: transform 320ms cubic-bezier(.4, 0, .2, 1);
  transition: transform 320ms var(--android-easing);
  transition: transform 320ms cubic-bezier(.4, 0, .2, 1), -webkit-transform 320ms cubic-bezier(.4, 0, .2, 1);
  transition: transform 320ms var(--android-easing), -webkit-transform 320ms var(--android-easing);
}
.ModalRoot--touched .ModalPage__in-wrap {
  transition: none;
}
.ModalPage__in {
  width: 700px;
  max-width: 100%;
  margin: 0 auto;
  background: #ffffff;
  background: #ffffff;
  background: var(--background_content);
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.ModalPage--ios .ModalPage__in {
  padding-top: 52px;
  padding-top: 52px;
  padding-top: var(--modalheader_height_ios);
  border-radius: 14px 14px 0 0;
}
.ModalPage--android .ModalPage__in {
  padding-top: 56px;
  padding-top: 56px;
  padding-top: var(--modalheader_height_android);
  border-radius: 12px 12px 0 0;
}
.ModalPage__header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ModalPage__content {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
  padding-bottom: 0px;
  padding-bottom: 0px;
  padding-bottom: var(--safe-area-inset-bottom);
}
.ModalRoot__modal--expandable .ModalPage__content {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.ModalPage__content-in {
  position: relative;
}
.ModalRoot__modal--collapsed .ModalPage__content,
.ModalRoot__modal--dragging .ModalPage__content {
  overflow: hidden;
  touch-action: pan-y;
}
.ModalPage__footer {
  background: #ffffff;
  background: #ffffff;
  background: var(--background_content);
  position: relative;
  z-index: 10;
}
.ModalRoot--touched .ModalPage__footer {
  transition: none;
}
.ModalPageHeader {}
.ModalPageHeader__in,
.ModalPageHeader__left,
.ModalPageHeader__right {
  box-sizing: border-box;
}
.ModalPageHeader__in {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #000000;
  color: #000000;
  color: var(--header_text_alternate);
  position: relative;
  z-index: 2;
}
.ModalPage--android .ModalPageHeader__in {
  height: 56px;
  height: 56px;
  height: var(--modalheader_height_android);
  padding: 0 4px;
}
.ModalPage--ios .ModalPageHeader__in {
  height: 52px;
  height: 52px;
  height: var(--modalheader_height_ios);
  padding: 0 2px;
}
.ModalPageHeader__left,
.ModalPageHeader__right {
  color: #528bcc;
  color: #528bcc;
  color: var(--header_tint_alternate);
}
.ModalPageHeader__left {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.ModalPageHeader__content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ModalPageHeader__content-in {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}
.ModalPageHeader__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  text-align: right;
}
.ModalPageHeader__shadow {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  transition: opacity 120ms ease-out;
}
.ModalRoot--touched .ModalPageHeader__shadow {
  transition: none;
}
.ModalPageHeader--android .ModalPageHeader__shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .06), 0 0 2px 0 rgba(0, 0, 0, .03);
}
.ModalPageHeader__shadow::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #d7d8d9;
  background: #d7d8d9;
  background: var(--separator_common);
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .ModalPageHeader__shadow::after {
    -webkit-transform: scaleY(.5);
            transform: scaleY(.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
  .ModalPageHeader__shadow::after {
    -webkit-transform: scaleY(.33);
            transform: scaleY(.33);
  }
}
.ModalPageHeader .HeaderButton .Icon--dismiss_24 {
  color: #99a2ad;
  color: #99a2ad;
  color: var(--placeholder_icon_foreground_primary);
}
.ModalPageHeader .HeaderButton .Icon--cancel_24 {
  color: #aaaeb3;
  color: #aaaeb3;
  color: var(--icon_secondary);
}
/**
 * iOS
 */
.ModalPageHeader--ios .ModalPageHeader__in {
  justify-content: space-between;
}
.ModalPageHeader--ios .ModalPageHeader__left {
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 12px;
  font-size: 17px;
}
.ModalPageHeader--ios .ModalPageHeader__left .HeaderButton .Icon--24 {
  padding-left: 12px;
}
.ModalPageHeader--ios .ModalPageHeader__left .HeaderButton > *:not(.Icon) {
  padding-left: 16px;
}
.ModalPageHeader--ios .ModalPageHeader__content {
  position: relative;
  z-index: 1;
}
.ModalPageHeader--ios .ModalPageHeader__content-in {
  font-size: 17px;
  line-height: 20px;
  text-align: center;
}
.ModalPageHeader--ios .ModalPageHeader__right {
  flex-grow: 1;
  flex-basis: 0;
  padding-left: 12px;
  font-size: 17px;
}
.ModalPageHeader--ios .ModalPageHeader__right .HeaderButton:not(:first-child) {
  padding-left: 12px;
}
.ModalPageHeader--ios .ModalPageHeader__right .HeaderButton .Icon--24 {
  padding-right: 12px;
}
.ModalPageHeader--ios .ModalPageHeader__right .HeaderButton > *:not(.Icon) {
  padding-right: 16px;
}
/**
 * Android
 */
.ModalPageHeader--android .ModalPageHeader__left {
  padding-right: 6px;
}
.ModalPageHeader--android .ModalPageHeader__left:empty {
  padding-right: 12px;
}
.ModalPageHeader--android .ModalPageHeader__left:not(:empty) {
  padding-left: 4px;
}
.ModalPageHeader--android .ModalPageHeader__dismiss {
  color: #aaaeb3;
  color: #aaaeb3;
  color: var(--icon_secondary);
}
.ModalPageHeader--android .ModalPageHeader__content {
  max-width: 100%;
  flex-grow: 2;
}
.ModalPageHeader--android .ModalPageHeader__content-in {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.ModalPageHeader--android .ModalPageHeader__right {
  padding-left: 6px;
}
.ModalPageHeader--android .ModalPageHeader__right:not(:empty) {
  padding-right: 4px;
}
/* Blocks */
.Tappable {}
/**
 * iOS tappable
 */
.Tappable--ios {
  transition: background-color .15s ease-out;
  }
.Tappable--ios.Tappable--active:not([disabled]):not(.TabsItem):not(.HeaderButton):not(.Button):not(.PanelHeaderContent__in):not(.ActionSheetItem) {
  background: #d7d8d9 !important;
  background: #d7d8d9 !important;
  background: var(--separator_common) !important;
  transition: none;
  }
/**
 * Android tappable
 */
.Tappable--android {
  position: relative;
  transition: background-color .15s ease-out;
  }
.Tappable--android.Tappable--active:not([disabled]):not(.TabsItem):not(.HeaderButton):not(.Button):not(.PanelHeaderContent__in) {
  background: rgba(0, 0, 0, 0.12) !important;
  background: rgba(0, 0, 0, 0.12) !important;
  background: var(--background_highlighted) !important;
  }
/**
   * Waves container
   */
.Tappable--android .Tappable__waves {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    overflow: hidden;
    }
/**
     * Wave
     */
.Tappable--android .Tappable__wave {
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      margin: -12px 0 0 -12px;
      opacity: 0;
      content: '';
      border-radius: 50%;
      background: rgba(127, 127, 127, .1);
      -webkit-animation: animation-wave .3s cubic-bezier(.4, 0, .2, 1);
              animation: animation-wave .3s cubic-bezier(.4, 0, .2, 1);
      -webkit-animation: animation-wave .3s cubic-bezier(.4, 0, .2, 1);
      -webkit-animation: animation-wave .3s var(--android-easing);
              animation: animation-wave .3s cubic-bezier(.4, 0, .2, 1);
              animation: animation-wave .3s var(--android-easing);
      }
/**
 * Animations
 */
@-webkit-keyframes animation-wave {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }

  30% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(8);
            transform: scale(8);
    opacity: 0;
  }
}
@keyframes animation-wave {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }

  30% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(8);
            transform: scale(8);
    opacity: 0;
  }
}
.Button {
  display: inline-block;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  cursor: default;
  border: 1px solid transparent;
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
          user-select: none;
  }
.Button__in {
    display: flex;
    align-items: center;
    }
.Button.Tappable--active {
  opacity: .6;
}
.Button[disabled] {
  opacity: .4;
  }
.Button--aln-left .Button__in {
    justify-content: flex-start;
    }
.Button--aln-center .Button__in {
    justify-content: center;
    }
.Button--aln-right .Button__in {
    justify-content: flex-end;
    }
.Button--str {
  display: block;
  max-width: 100%;
  flex-grow: 1;
  }
.Button--str:first-child:last-child {
  width: 100%;
  }
.Button--stretched .Button__in {
    justify-content: center;
    }
.Button__before .Icon--16 {
      margin-right: 6px;
      }
.Button__before .Icon--24 {
      margin-right: 8px;
      }
.Button:not(.Button--sz-xl) .Button__after {
    margin-left: 8px;
    }
.Button::before,
  .Button::after {
    display: none;
    }
.Button--lvl-primary {
  background-color: #5181b8;
  background-color: #5181b8;
  background-color: var(--button_primary_background);
  color: #ffffff;
  color: #ffffff;
  color: var(--button_primary_foreground);
  }
.Button--lvl-secondary {
  background-color: rgba(0, 57, 115, 0.10);
  background-color: rgba(0, 57, 115, 0.10);
  background-color: var(--button_secondary_background);
  color: #45678f;
  color: #45678f;
  color: var(--button_secondary_foreground);
  }
.Button--lvl-tertiary {
  background-color: rgba(0, 0, 0, 0.00);
  background-color: rgba(0, 0, 0, 0.00);
  background-color: var(--button_tertiary_background);
  color: #5181b8;
  color: #5181b8;
  color: var(--button_tertiary_foreground);
  }
.Button--lvl-commerce {
  background-color: #4bb34b;
  background-color: #4bb34b;
  background-color: var(--button_commerce_background);
  color: #ffffff;
  color: #ffffff;
  color: var(--button_commerce_foreground);
  }
.Button--lvl-outline {
  background-color: transparent;
  border: 1px solid #528bcc;
  border: 1px solid #528bcc;
  border: 1px solid var(--button_outline_border);
  color: #528bcc;
  color: #528bcc;
  color: var(--button_outline_foreground);
  }
.Button--lvl-destructive {
  background-color: #e64646;
  background-color: #e64646;
  background-color: var(--destructive);
  color: #fff;
  }
.Button--sz-m {
  padding: 0 16px;
  }
.Button--sz-m .Button__before {
    margin-left: -4px;
    }
.Button--sz-m .Button__content {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    padding: 7px 0;
    }
.Button--sz-l {
  padding: 0 16px;
  }
.Button--sz-l .Button__before {
    margin-left: -4px;
    }
.Button--sz-l .Button__content {
    font-size: 15px;
    font-weight: 500;
    padding: 8px 0;
    }
.Button--sz-xl {
  display: block;
  width: 100%;
  padding: 0 16px;
  }
.Button--sz-xl .Button__before {
    margin-left: -6px;
    margin-right: 2px;
    }
.Button--sz-xl .Button__before ~ .Button__content {
    padding-left: 0;
    }
.Button--sz-xl .Button__in {
    justify-content: center;
    }
.Button--sz-xl .Button__content {
    font-size: 17px;
    font-weight: 500;
    padding: 11px;
    }
/*
  iOS
 */
.Button--ios {
  border-radius: 10px;
}
/*
  Android
 */
.Button--android {
  border-radius: 8px;
}
/**
 * Counter
 */
.Button--lvl-primary .Counter {
  background-color: #ffffff;
  background-color: #ffffff;
  background-color: var(--button_primary_foreground);
  color: #5181b8;
  color: #5181b8;
  color: var(--button_primary_background);
  }
.Button--lvl-secondary .Counter {
  background-color: #45678f;
  background-color: #45678f;
  background-color: var(--button_secondary_foreground);
  color: #ffffff;
  color: #ffffff;
  color: var(--background_content);
  }
.Button--lvl-tertiary .Counter {
  background-color: #5181b8;
  background-color: #5181b8;
  background-color: var(--button_tertiary_foreground);
  color: #ffffff;
  color: #ffffff;
  color: var(--background_content);
  }
.Button--lvl-outline .Counter {
  background-color: #528bcc;
  background-color: #528bcc;
  background-color: var(--button_outline_foreground);
  color: #ffffff;
  color: #ffffff;
  color: var(--background_content);
  }
.Button--lvl-commerce .Counter {
  background-color: #ffffff;
  background-color: #ffffff;
  background-color: var(--button_commerce_foreground);
  color: #4bb34b;
  color: #4bb34b;
  color: var(--button_commerce_background);
  }
.CellButton {
  display: block;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: default;
  width: 100%;
  background: transparent;
  position: relative;
  }
.CellButton[disabled] {
  opacity: .4;
  }
.CellButton__in {
  display: flex;
  align-items: center;
  }
.CellButton--aln-left .CellButton__in {
  justify-content: flex-start;
  }
.CellButton--aln-center .CellButton__in {
  justify-content: center;
  }
.CellButton--aln-right .CellButton__in {
  justify-content: flex-end;
  }
.CellButton.Tappable--active {
  z-index: 2;
  }
.CellButton--lvl-primary {
  color: #528bcc;
  color: #528bcc;
  color: var(--accent);
  }
.CellButton--lvl-danger {
  color: #e64646;
  color: #e64646;
  color: var(--destructive);
  }
/*
  iOS
 */
.CellButton--ios {
  padding: 0 12px;
  }
.CellButton .CellButton__content {
  line-height: 20px;
  font-size: 17px;
  padding: 12px 0;
  }
.CellButton--ios .CellButton__before .Icon {
  margin-right: 12px;
  }
/*
  Android
 */
.CellButton--android {
  padding: 0 16px;
  }
.CellButton--android .CellButton__content {
  font-size: 16px;
  line-height: 20px;
  padding: 14px 0;
  }
.CellButton--android .CellButton__before .Icon {
  margin-right: 12px;
  }
.Div {
  -webkit-user-select: text;
          user-select: text;
  }
.Div--ios {
  padding: 12px;
  }
.Div--android {
  padding: 12px 16px;
  }
.Link {
  color: #528bcc;
  color: #528bcc;
  color: var(--accent);
  text-decoration: none;
  }
.Header {
  -webkit-user-select: text;
          user-select: text;
  }
.Header__in {
    display: flex;
    align-items: center;
    justify-content: space-between;
    }
.Header__content {
      min-width: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
.Header--level-1 .Header__content {
      color: #000000;
      color: #000000;
      color: var(--text_primary);
      font-size: 17px;
      line-height: 20px;
      font-weight: 500;
      }
.Header--level-2 .Header__content {
      color: #909499;
      color: #909499;
      color: var(--text_secondary);
      font-size: 13px;
      line-height: 15px;
      font-weight: 600;
      text-transform: uppercase;
      }
.Header__indicator {
      margin-left: 10px;
      margin-right: auto;
      }
.Header__aside {
      white-space: nowrap;
      margin-left: 12px;
      }
.Header--ios {
  padding: 0 12px;
  }
.Header--ios .Header__aside {
    font-size: 15px;
    }
.Header--ios.Header--level-1 .Header__content {
    padding: 14px 0;
    }
.Header--ios.Header--level-2 .Header__content {
    padding: 15px 0 14px;
    }
.Header--android {
  padding: 0 16px;
  }
.Header--android .Header__aside {
    font-size: 14px;
    font-weight: 500;
    }
.Header--android.Header--level-1 .Header__content {
    padding: 18px 0;
    }
.Header--android.Header--level-2 .Header__content {
    padding: 15px 0 14px;
    }
.Group {
  background: #ffffff;
  background: #ffffff;
  background: var(--background_content);
  }
.Group__content {
    color: #000000;
    color: #000000;
    color: var(--text_primary);
    }
/**
 * iOS
 */
.Group--ios {
  margin: 10px 0;
  position: relative;
  }
.Group--ios .Group__content .List::before,
  .Group--ios .Group__content .List::after {
    content: none;
    }
.Group--ios .Group__description {
    font-size: 14px;
    line-height: 16px;
    color: #909499;
    color: #909499;
    color: var(--text_secondary);
    padding: 8px 12px;
    }
.Group--ios::before,
  .Group--ios::after {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    height: 1px;
    content: '';
    background: #d7d8d9;
    background: #d7d8d9;
    background: var(--separator_common);
    }
.Group--ios::before {
    bottom: 100%;
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    }
.Group--ios::after {
    top: 100%;
    -webkit-transform-origin: center top;
            transform-origin: center top;
    }
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    .Group--ios::before,
    .Group--ios::after {
      -webkit-transform: scaleY(.5);
              transform: scaleY(.5);
      }
    }
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
    .Group--ios::before,
    .Group--ios::after {
      -webkit-transform: scaleY(.33);
              transform: scaleY(.33);
      }
    }
/**
 * Android
 */
.Group--android {
  margin: 8px 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .06), 0 0 2px 0 rgba(0, 0, 0, .03);
  }
.Group--android .Group__description {
    font-size: 14px;
    line-height: 16px;
    color: #909499;
    color: #909499;
    color: var(--text_secondary);
    padding: 12px 16px 8px;
    }
.Panel__in-before + .Group--android,
.PullToRefresh__content > .Group--android:first-child {
  margin-top: 0;
  padding-top: 8px;
  }
.Cell {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 22px;
  }
.List--dragging .Cell:not(.Cell--dragging) {
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease;
  }
.List--dragging .Cell--dragging,
.Cell--dragging {
  background-color: #ffffff;
  background-color: #ffffff;
  background-color: var(--background_content);
  box-shadow: 0 0 5px rgba(0, 0, 0, .25);
  z-index: 3;
  }
.Cell__in {
    position: relative;
    display: flex;
    max-width: 100%;
    overflow: hidden;
    text-decoration: none;
    }
.Cell--m .Cell__in {
    align-items: center;
    }
.Cell--l .Cell__in {
    align-items: flex-start;
    }
.Cell__checkbox {
      display: none;
      }
.Cell__before {
      position: relative;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      align-self: stretch;
      }
.Cell--l .Cell__before-in {
        align-self: flex-start;
        }
.Cell__before-in {
        flex-shrink: 0;
        color: #aaaeb3;
        color: #aaaeb3;
        color: var(--icon_secondary);
        }
.Cell__checkbox-marker {
        box-sizing: border-box;
        }
.Cell__main {
      flex-grow: 1;
      max-width: 100%;
      color: #000000;
      color: #000000;
      color: var(--text_primary);
      white-space: nowrap;
      overflow: hidden;
      }
.Cell--multiline .Cell__main {
      white-space: normal;
      }
.Cell__description {
        color: #909499;
        color: #909499;
        color: var(--text_secondary);
        margin-top: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        }
.Cell--l .Cell__description {
        margin-top: 0;
        }
.Cell__children {
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        }
.Cell--l .Cell__children {
        font-size: 15px;
        font-weight: 500;
        }
.Cell__bottom {
        margin-top: 8px;
        }
.Cell__indicator,
    .Cell__aside {
      overflow: hidden;
      max-width: 75%;
      color: #909499;
      color: #909499;
      color: var(--text_secondary);
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
.Cell__aside {
      flex-shrink: 0;
      overflow: visible;
      }
.Cell--l .Cell__aside {
      align-self: center;
      }
.Cell__indicator + .Cell__aside:not(:empty) {
      padding-left: 8px;
      }
.Cell__dragger {
        position: relative;
        color: #aaaeb3;
        color: #aaaeb3;
        color: var(--icon_secondary);
        }
.Cell__dragger::after {
          position: absolute;
          display: block;
          box-sizing: border-box;
          content: '';
          }
/* iOS */
.Cell--ios {
  overflow: hidden;
  }
.Cell--ios .Cell__remove {
    position: absolute;
    left: 100%;
    height: 100%;
    top: 0;
    background-color: #e64646;
    background-color: #e64646;
    background-color: var(--destructive);
    color: #fff;
    color: #fff;
    color: var(--white);
    padding: 0 10px;
    line-height: 45px;
    font-size: 15px;
    transition: -webkit-transform .6s cubic-bezier(.36, .66, .04, 1);
    transition: transform .6s cubic-bezier(.36, .66, .04, 1);
    transition: transform .6s cubic-bezier(.36, .66, .04, 1), -webkit-transform .6s cubic-bezier(.36, .66, .04, 1);
    transition: -webkit-transform .6s cubic-bezier(.36, .66, .04, 1);
    transition: -webkit-transform .6s var(--ios-easing);
    transition: transform .6s cubic-bezier(.36, .66, .04, 1);
    transition: transform .6s var(--ios-easing);
    transition: transform .6s cubic-bezier(.36, .66, .04, 1), -webkit-transform .6s cubic-bezier(.36, .66, .04, 1);
    transition: transform .6s var(--ios-easing), -webkit-transform .6s var(--ios-easing);
    display: flex;
    align-items: center;
    justify-content: center;
    }
.Cell--ios .Cell__remove-in {
      flex-shrink: 0;
      }
.Cell--ios .Cell__chevron {
    color: #c4c8cc;
    color: #c4c8cc;
    color: var(--icon_tertiary);
    }
.Cell--ios .Cell__in {
    padding: 0 12px;
    transition: background-color .15s ease-out, -webkit-transform .6s cubic-bezier(.36, .66, .04, 1);
    transition: background-color .15s ease-out, transform .6s cubic-bezier(.36, .66, .04, 1);
    transition: background-color .15s ease-out, transform .6s cubic-bezier(.36, .66, .04, 1), -webkit-transform .6s cubic-bezier(.36, .66, .04, 1);
    transition: background-color .15s ease-out, -webkit-transform .6s cubic-bezier(.36, .66, .04, 1);
    transition: background-color .15s ease-out, -webkit-transform .6s var(--ios-easing);
    transition: background-color .15s ease-out, transform .6s cubic-bezier(.36, .66, .04, 1);
    transition: background-color .15s ease-out, transform .6s var(--ios-easing);
    transition: background-color .15s ease-out, transform .6s cubic-bezier(.36, .66, .04, 1), -webkit-transform .6s cubic-bezier(.36, .66, .04, 1);
    transition: background-color .15s ease-out, transform .6s var(--ios-easing), -webkit-transform .6s var(--ios-easing);
    }
.List .Cell--ios:not(:last-child) .Cell__in:not(.Tappable--active) .Cell__before::before {
      position: absolute;
      bottom: 0;
      width: 10000px;
      height: 1px;
      background: #d7d8d9;
      background: #d7d8d9;
      background: var(--separator_common);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      content: '';
      }
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      .List .Cell--ios:not(:last-child) .Cell__in:not(.Tappable--active) .Cell__before::before {
        -webkit-transform: scaleY(.5);
                transform: scaleY(.5);
        }
      }
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
      .List .Cell--ios:not(:last-child) .Cell__in:not(.Tappable--active) .Cell__before::before {
        -webkit-transform: scaleY(.33);
                transform: scaleY(.33);
        }
      }
.List .Cell--ios.Cell--m .Cell__before::before {
      left: 100%;
      }
.List .Cell--ios.Cell--l .Cell__before::before {
      left: -12px;
      }
.Cell--ios .Cell__before-in > .Icon {
        padding: 10px 16px 10px 0;
        }
.Cell--ios.Cell--m .Cell__before-in > .Avatar {
        padding: 4px 12px 4px 0;
        }
.Cell--ios .Cell__dragger::after {
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        right: -10px;
        top: -10px;
        }
.Cell--ios.Cell--l .Cell__before-in > .Avatar {
        padding: 12px 12px 12px 0;
        }
.Cell--ios .Cell__checkbox-marker {
        margin: 10px 16px 10px 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid #aaaeb3;
        border: 2px solid #aaaeb3;
        border: 2px solid var(--icon_secondary);
        color: transparent;
        }
.Cell--ios .Cell__checkbox:checked ~ .Cell__before .Cell__checkbox-marker {
        border: none;
        color: #fff;
        color: #fff;
        color: var(--white);
        background-color: #528bcc;
        background-color: #528bcc;
        background-color: var(--accent);
        padding: 4px;
        }
.Cell--ios .Cell__remove-marker {
        margin: 10px 16px 10px 0;
        width: 21px;
        height: 21px;
        background-color: #e64646;
        background-color: #e64646;
        background-color: var(--destructive);
        border-radius: 50%;
        position: relative;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, .06);
        }
.Cell--ios .Cell__remove-marker::before {
          position: absolute;
          left: 6px;
          top: 10px;
          width: 9px;
          height: 1px;
          background-color: #fff;
          background-color: #fff;
          background-color: var(--white);
          content: '';
          display: block;
          }
.Cell--ios .Cell__main {
      padding: 11px 8px 11px 0;
      }
.Cell--ios .Cell__description:not(:empty) {
        font-size: 13px;
        line-height: 15px;
        }
.Cell--ios .Cell__indicator,
    .Cell--ios .Cell__aside {
      padding-top: 10px;
      padding-bottom: 10px;
      }
/* Android */
.Cell--android {
  font-size: 16px;
  }
.Cell--android .Cell__in {
    padding: 0 16px;
    }
.Cell--android .Cell__before-in > .Icon,
    .Cell--android .Cell__dragger > .Icon {
      padding: 12px 16px 12px 0;
      }
.Cell--android .Cell__dragger::after {
      width: calc(100% + 16px);
      height: 100%;
      right: 0;
      top: 0;
      }
.Cell--android .Cell__before-in > .Avatar {
      padding: 8px 12px 8px 0;
      }
.Cell--android.Cell--l .Cell__before-in > .Avatar {
      padding: 12px 12px 12px 0;
      }
.Cell--android .Cell__main {
      padding: 14px 8px 14px 0;
      line-height: 20px;
      }
.Cell--android.Cell--l .Cell__main {
      padding: 12px 8px 12px 0;
      }
.Cell--android .Cell__description:not(:empty) {
        font-size: 14px;
        line-height: 18px;
        }
.Cell--android .Cell__checkbox-marker {
      width: 18px;
      height: 18px;
      border: 2px solid #aaaeb3;
      border: 2px solid #aaaeb3;
      border: 2px solid var(--icon_secondary);
      color: transparent;
      border-radius: 3px;
      }
.Cell--android .Cell__checkbox:checked ~ .Cell__aside .Cell__checkbox-marker {
      color: #fff;
      border: none;
      padding: 1px;
      background-color: #528bcc;
      background-color: #528bcc;
      background-color: var(--accent);
      }
.Cell--android .Cell__indicator,
    .Cell--android .Cell__aside {
      padding-top: 12px;
      padding-bottom: 12px;
      }
.List {
  position: relative;
  margin: 0;
  padding: 0;
  background: #ffffff;
  background: #ffffff;
  background: var(--background_content);
  }
.List .Cell,
  .List .Radio {
    position: relative;
    z-index: 2;
    }
/* iOS */
.List--ios {}
.List--ios::before,
  .List--ios::after {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    height: 1px;
    content: '';
    }
.List--ios::before {
    bottom: 100%;
    background: #d7d8d9;
    background: #d7d8d9;
    background: var(--separator_common);
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    }
.List--ios::after {
    top: 100%;
    background: #d7d8d9;
    background: #d7d8d9;
    background: var(--separator_common);
    -webkit-transform-origin: center top;
            transform-origin: center top;
    }
.List--ios:empty::before,
  .List--ios:empty::after {
    content: none;
    }
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .List--ios::before,
  .List--ios::after {
    -webkit-transform: scaleY(.5);
            transform: scaleY(.5);
    }
  }
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
  .List--ios::before,
  .List--ios::after {
    -webkit-transform: scaleY(.33);
            transform: scaleY(.33);
    }
  }
.Footer {
  margin: 24px 0;
  font-size: 13px;
  color: #909499;
  color: #909499;
  color: var(--text_secondary);
  line-height: 16px;
  font-weight: normal;
  text-align: center;
  }
.Div.Footer {
  padding-top: 0;
  padding-bottom: 0;
  }
:root {
  --duration: .7s;
}
.Spinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  }
.Spinner--ios {
  color: #aeb7c2;
  color: #aeb7c2;
  color: var(--activity_indicator_tint);
  }
.Spinner--android {
  color: #528bcc;
  color: #528bcc;
  color: var(--accent);
  }
.PanelHeader .Spinner {
  color: currentColor;
  }
.Spinner__self {
    -webkit-animation: rotator .7s linear infinite;
            animation: rotator .7s linear infinite;
    -webkit-animation: rotator .7s linear infinite;
    -webkit-animation: rotator var(--duration) linear infinite;
            animation: rotator .7s linear infinite;
            animation: rotator var(--duration) linear infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
    }
@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    }
  }
@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    }
  }
.Switch {
  display: block;
  padding: 8px 15px;
  }
.Switch__self {
    display: none;
    }
.Switch__pseudo {
    position: relative;
    display: block;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    }
.Switch__pseudo::before {
    position: absolute;
    content: '';
    }
.Switch__self:checked + .Switch__pseudo::before {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
    }
/**
 * iOS
 */
.Switch--ios {
  margin: -12px -15px;
  }
.Switch--ios .Switch__pseudo {
    width: 50px;
    height: 30px;
    border: 2px solid #e1e3e6;
    border: 2px solid #e1e3e6;
    border: 2px solid var(--switch_ios_off_border);
    background: transparent;
    border-radius: 15px;
    box-sizing: border-box;
    transition:
      background-color .2s ease,
      border-color .2s ease;
    }
.Switch--ios .Switch__self:checked + .Switch__pseudo {
    border-color: #528bcc;
    border-color: #528bcc;
    border-color: var(--accent);
    background: #528bcc;
    background: #528bcc;
    background: var(--accent);
    }
.Switch--ios .Switch__self[disabled] + .Switch__pseudo {
    opacity: .4;
    }
.Switch--ios .Switch__pseudo::before {
    top: 0;
    left: 0;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background: #fff;
    background: #fff;
    background: var(--white);
    box-shadow: 0 3px 8px rgba(0, 0, 0, .15), 0 3px 1px rgba(0, 0, 0, .06), inset 0 0 0 .5px rgba(0, 0, 0, .04);
    transition: -webkit-transform .2s cubic-bezier(.36, -.24, .26, 1.32);
    transition: transform .2s cubic-bezier(.36, -.24, .26, 1.32);
    transition: transform .2s cubic-bezier(.36, -.24, .26, 1.32), -webkit-transform .2s cubic-bezier(.36, -.24, .26, 1.32);
    }
.Switch--ios .Switch__self:checked + .Switch__pseudo::before {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
    }
/**
 * Android
 */
/* TODO поддержкать темы */
.Switch--android {
  margin: -4px -15px;
  }
.Switch--android .Switch__pseudo {
    width: 34px;
    height: 14px;
    background: #c6c5c5;
    border-radius: 7px;
    transition: background-color .1s ease;
    }
.Switch--android .Switch__self:checked + .Switch__pseudo {
    background: #a8bfdb;
    }
.Switch--android .Switch__self[disabled] + .Switch__pseudo {
    opacity: .4;
    }
.Switch--android .Switch__pseudo::before {
    top: -3px;
    left: -3px;
    width: 20px;
    height: 20px;
    background: #f1f1f1;
    border-radius: 10px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, .12), 0 2px 2px 0 rgba(0, 0, 0, .24);
    transition: -webkit-transform .1s ease;
    transition: transform .1s ease;
    transition: transform .1s ease, -webkit-transform .1s ease;
    }
.Switch--android .Switch__self:checked + .Switch__pseudo::before {
    background: #528bcc;
    background: #528bcc;
    background: var(--accent);
    }
.InfoRow {
  -webkit-user-select: text;
          user-select: text;
  }
.InfoRow__title {
    font-size: 14px;
    line-height: 16px;
    color: #909499;
    color: #909499;
    color: var(--text_secondary);
    margin-bottom: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    }
.Avatar {
  box-sizing: border-box;
  }
.Avatar__in {
    position: relative;
    color: #aaaeb3;
    color: #aaaeb3;
    color: var(--icon_secondary);
    }
.Avatar__img {
      background: rgba(0, 29, 61, 0.08);
      background: rgba(0, 29, 61, 0.08);
      background: var(--placeholder_icon_background);
      border: none;
      display: block;
      max-width: 100%;
      position: relative;
      z-index: 1;
      }
.Avatar__children {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      }
.Entity {
  position: relative;
  text-align: left;
  display: flex;
  }
.Entity__aside {
    margin-right: 12px;
    flex-shrink: 0;
    }
.Entity__main {
    height: 100%;
    }
.Entity__title {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: #000000;
      color: #000000;
      color: var(--text_primary);
      }
.Entity__description {
      font-size: 14px;
      line-height: 16px;
      color: #909499;
      color: #909499;
      color: var(--text_secondary);
      }
.Entity__content {
      margin-top: 6px;
      }
.Gallery {
  position: relative;
  overflow: hidden;
  height: 100px;
  }
.Gallery__viewport {
    margin: 0;
    height: 100%;
    position: relative;
    }
.Gallery--center .Gallery__viewport {
    margin: 0 auto;
  }
.Gallery__layer {
    display: flex;
    width: 100%;
    height: 100%;
    cursor: grab;
    align-items: stretch;
    }
.Gallery__slide {
    width: 100%;
    height: 100%;
    flex: 0 0 auto;
    max-width: 100%;
    overflow: hidden;
    }
.Gallery--custom-width .Gallery__slide {
  width: auto;
}
.Gallery__slide > * {
      width: 100%;
      height: 100%;
      }
.Gallery__bullets {
    position: absolute;
    bottom: 10px;
    height: 6px;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    }
.Gallery__bullets--dark .Gallery__bullet {
      background-color: #000;
      }
.Gallery__bullets--light .Gallery__bullet {
      background-color: #fff;
      }
.Gallery__bullet {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin: 0 3px;
      opacity: .56;
      }
.Gallery__bullet--active {
      opacity: 1;
      }
.Progress {
  border-radius: 2px;
  background: rgba(0, 29, 61, 0.08);
  background: rgba(0, 29, 61, 0.08);
  background: var(--placeholder_icon_background);
  }
.Progress__in {
    height: 4px;
    border-radius: 2px;
    transition: width .2s ease;
    background: #528bcc;
    background: #528bcc;
    background: var(--accent);
    }
.Search--ios {
  overflow: hidden;
  font-weight: normal;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  }
.Search--ios .Search__in {
    position: relative;
    }
.Search--ios .Search__width {
      height: 32px;
      white-space: nowrap;
      color: transparent;
      width: 10000px;
      }
.Search--ios .Search__control {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100% - 1px);
      height: 100%;
      border-radius: 16px;
      }
.Search--ios .Search__input {
        border: none;
        margin: 0;
        height: 32px;
        position: relative;
        padding: 0 22px 0 34px;
        box-sizing: border-box;
        font-size: 15px;
        outline: none;
        border-radius: 16px;
        max-width: 100%;
        flex-grow: 1;
        min-width: 0;
        background-color: transparent;
        z-index: 2;
        }
.Search--ios.Search--has-after .Search__input {
        border-radius: 16px 0 0 16px;
        padding-right: 16px;
        }
.Search--ios .Search__after-width {
        font-size: 17px;
        height: 32px;
        line-height: 32px;
        padding-left: 12px;
        padding-right: 4px;
        }
.Search--ios .Search__placeholder {
        position: absolute;
        left: 0;
        -webkit-transform: translate(50%);
                transform: translate(50%);
        transition: -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
        transition: transform .3s cubic-bezier(.36, .66, .04, 1);
        transition: transform .3s cubic-bezier(.36, .66, .04, 1), -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
        transition: -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
        transition: -webkit-transform .3s var(--ios-easing);
        transition: transform .3s cubic-bezier(.36, .66, .04, 1);
        transition: transform .3s var(--ios-easing);
        transition: transform .3s cubic-bezier(.36, .66, .04, 1), -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
        transition: transform .3s var(--ios-easing), -webkit-transform .3s var(--ios-easing);
        width: 100%;
        cursor: text;
        opacity: .5;
        z-index: 1;
        text-align: left;
        display: block;
        height: 32px;
        font-size: 0;
        }
.Search--ios.Search--focused .Search__placeholder,
      .Search--ios.Search--has-value .Search__placeholder {
        -webkit-transform: translate(0);
                transform: translate(0);
        }
.Search--ios .Search__placeholder-in {
          -webkit-transform: translate(-50%);
                  transform: translate(-50%);
          transition: -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
          transition: transform .3s cubic-bezier(.36, .66, .04, 1);
          transition: transform .3s cubic-bezier(.36, .66, .04, 1), -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
          transition: -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
          transition: -webkit-transform .3s var(--ios-easing);
          transition: transform .3s cubic-bezier(.36, .66, .04, 1);
          transition: transform .3s var(--ios-easing);
          transition: transform .3s cubic-bezier(.36, .66, .04, 1), -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
          transition: transform .3s var(--ios-easing), -webkit-transform .3s var(--ios-easing);
          height: 32px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          }
.Search--ios.Search--focused .Search__placeholder .Search__placeholder-in,
        .Search--ios.Search--has-value .Search__placeholder .Search__placeholder-in {
          -webkit-transform: translate(12px);
                  transform: translate(12px);
          }
.Search--ios .Search__placeholder-text {
            margin-left: 6px;
            line-height: 32px;
            font-size: 15px;
            }
.Search--ios.Search--has-value .Search__placeholder-text {
            opacity: 0;
            }
.Search--ios .Search__after {
      position: absolute;
      left: 100%;
      top: 0;
      line-height: 32px;
      z-index: 2;
      -webkit-transform: translate(0);
              transform: translate(0);
      transition: -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
      transition: transform .3s cubic-bezier(.36, .66, .04, 1);
      transition: transform .3s cubic-bezier(.36, .66, .04, 1), -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
      transition: -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
      transition: -webkit-transform .3s var(--ios-easing);
      transition: transform .3s cubic-bezier(.36, .66, .04, 1);
      transition: transform .3s var(--ios-easing);
      transition: transform .3s cubic-bezier(.36, .66, .04, 1), -webkit-transform .3s cubic-bezier(.36, .66, .04, 1);
      transition: transform .3s var(--ios-easing), -webkit-transform .3s var(--ios-easing);
      cursor: default;
      }
.Search--ios.Search--focused .Search__after,
    .Search--ios.Search--has-value .Search__after {
      -webkit-transform: translate(-100%);
              transform: translate(-100%);
      }
.Search--ios .Search__after::before {
        position: absolute;
        right: calc(100% - 1px);
        display: block;
        height: 100%;
        width: 16px;
        content: '';
        pointer-events: none;
        }
.Search--ios .Search__after-in {
        position: relative;
        z-index: 2;
        cursor: pointer;
        font-size: 17px;
        padding-left: 12px;
        padding-right: 4px;
        }
.Search--ios .Search__after::after {
        position: absolute;
        right: 100%;
        display: block;
        height: 100%;
        width: 16px;
        content: '';
        top: 0;
        border-radius: 0 16px 16px 0;
        cursor: text;
        pointer-events: none;
        }
/*
  Themes
 */
/*
  default
 */
.Search--ios.Search--default {
  background: #ebedf0;
  background: #ebedf0;
  background: var(--search_bar_background);
  padding: 8px;
  }
.Search--ios.Search--default .Search__control {
  background-color: #ffffff;
  background-color: #ffffff;
  background-color: var(--search_bar_field_background);
  }
.Search--ios.Search--default .Search__input {
  color: #000000;
  color: #000000;
  color: var(--text_primary);
  }
.Search--ios.Search--default .Search__placeholder {
  color: #99a2ad;
  color: #99a2ad;
  color: var(--search_bar_field_tint);
  }
.Search--ios.Search--default .Search__after-width {
  background: #ffffff;
  background: #ffffff;
  background: var(--search_bar_field_background);
  color: #ffffff;
  color: #ffffff;
  color: var(--search_bar_field_background);
  }
.Search--ios.Search--default .Search__after {
  background: #ebedf0;
  background: #ebedf0;
  background: var(--search_bar_background);
  color: #528bcc;
  color: #528bcc;
  color: var(--accent);
  }
.Search--ios.Search--default .Search__after::after {
  background-color: #ffffff;
  background-color: #ffffff;
  background-color: var(--search_bar_field_background);
  }
.Search--ios.Search--default .Search__after::before {
  background-color: #ebedf0;
  background-color: #ebedf0;
  background-color: var(--search_bar_background);
  }
/* Очень плохой хак для новых тем. В них заливка поиска прозрачная, а при текущей его реализации
 * прозрачность все ломает. Нужно его переделать и убрать хаки.
 */
[scheme="bright_light"] .Panel--ios.Panel--tm-gray .Search--default .Search__after::before,
[scheme="bright_light"] .Panel--ios.Panel--tm-gray .Search--default .Search__after,
[scheme="bright_light"] .Panel--ios.Panel--tm-gray .Search--default {
  background-color: #ebedf0;
  background-color: #ebedf0;
  background-color: var(--background_page);
  }
[scheme="bright_light"] .Panel--ios.Panel--tm-white .Search--default .Search__after::before,
[scheme="bright_light"] .Panel--ios.Panel--tm-white .Search--default .Search__after,
[scheme="bright_light"] .Panel--ios.Panel--tm-white .Search--default {
  background-color: #ffffff;
  background-color: #ffffff;
  background-color: var(--background_content);
  }
[scheme="space_gray"] .Panel--ios.Panel--tm-gray .Search--default .Search__after::before,
[scheme="space_gray"] .Panel--ios.Panel--tm-gray .Search--default .Search__after,
[scheme="space_gray"] .Panel--ios.Panel--tm-gray .Search--default {
  background-color: #ebedf0;
  background-color: #ebedf0;
  background-color: var(--background_page);
  }
[scheme="space_gray"] .Panel--ios.Panel--tm-white .Search--default .Search__after::before,
[scheme="space_gray"] .Panel--ios.Panel--tm-white .Search--default .Search__after,
[scheme="space_gray"] .Panel--ios.Panel--tm-white .Search--default {
  background-color: #ffffff;
  background-color: #ffffff;
  background-color: var(--background_content);
  }
.Search--ios.Search--default::after {
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 1px;
  content: '';
  left: 0;
  bottom: 0;
  background: #d7d8d9;
  background: #d7d8d9;
  background: var(--separator_common);
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  }
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .Search--ios.Search--default::after {
    -webkit-transform: scaleY(.5);
            transform: scaleY(.5);
    }
  }
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
  .Search--ios.Search--default::after {
    -webkit-transform: scaleY(.33);
            transform: scaleY(.33);
    }
  }
/*
  header
 */
.Search--ios.Search--header {
  background: #5181b8;
  background: #5181b8;
  background: var(--header_background);
  padding-top: 6px;
  }
.Search--ios.Search--header .Search__control {
  background-color: #28436e;
  background-color: #28436e;
  background-color: var(--header_search_field_background);
  }
.Search--ios.Search--header .Search__input {
  color: #ffffff;
  color: #ffffff;
  color: var(--header_tint);
  }
.Search--ios.Search--header .Search__placeholder {
  color: #92b2d6;
  color: #92b2d6;
  color: var(--header_search_field_tint);
  }
.Search--ios.Search--header .Search__after-width {
  background: #28436e;
  background: #28436e;
  background: var(--header_search_field_background);
  color: #28436e;
  color: #28436e;
  color: var(--header_search_field_background);
  }
.Search--ios.Search--header .Search__after {
  background: #5181b8;
  background: #5181b8;
  background: var(--header_background);
  color: #ffffff;
  color: #ffffff;
  color: var(--header_tint);
  }
.Search--ios.Search--header .Search__after::after {
  background-color: #28436e;
  background-color: #28436e;
  background-color: var(--header_search_field_background);
  }
.Search--ios.Search--header .Search__after::before {
  background-color: #5181b8;
  background-color: #5181b8;
  background-color: var(--header_background);
  }
.Search--android {
  height: 56px;
  box-sizing: border-box;
  width: 100%;
  background: #5181b8;
  background: #5181b8;
  background: var(--header_background);
  }
.Search--android .Search__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    }
.Search--android .Search__before,
  .Search--android .Search__after {
    flex-shrink: 0;
    }
.Search--android .Search__control {
    max-width: 100%;
    flex-grow: 1;
    }
.Search--android .Search__input {
      border: none;
      display: block;
      width: 100%;
      padding: 0;
      box-sizing: border-box;
      outline: none;
      font-size: 16px;
      line-height: 20px;
      background-color: transparent;
      }
/*
  header theme
 */
.Search--android.Search--header {
  padding: 4px;
  }
.Search--android.Search--header .Search__before {
  margin-right: 12px;
  }
.Search--android.Search--header .Search__after {
  margin-left: 12px;
  }
.Search--android.Search--header.Search--vkapps .Search__after {
  margin-right: 90px;
  }
.Search--android.Search--header .Search__input {
  color: #ffffff;
  color: #ffffff;
  color: var(--header_tint);
  padding: 15px 0 13px;
  }
.Search--android.Search--header .Search__input::-webkit-input-placeholder {
  color: #92b2d6;
  color: #92b2d6;
  color: var(--header_search_field_tint);
  opacity: .6;
  }
.Search--android.Search--header .Search__input::placeholder {
  color: #92b2d6;
  color: #92b2d6;
  color: var(--header_search_field_tint);
  opacity: .6;
  }
/*
  default theme
 */
.Search--android.Search--default {
  padding: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, .08), 0 4px 4px rgba(0, 0, 0, .16);
  position: relative;
  z-index: 2;
  }
.Search--android.Search--default .Search__container {
  background-color: #28436e;
  background-color: #28436e;
  background-color: var(--header_search_field_background);
  border-radius: 8px;
  padding: 0 4px;
  }
.Search--android.Search--default .Search__before {
  margin-right: 8px;
  }
.Search--android.Search--default .Search__after {
  margin-left: 8px;
  }
.Search--android.Search--default .Search__before .Icon,
.Search--android.Search--default .Search__after .Icon {
  padding: 8px;
  color: #aaaeb3;
  color: #aaaeb3;
  color: var(--icon_secondary);
  }
.Search--android.Search--default .Search__input {
  padding: 11px 0 9px;
  color: #ffffff;
  color: #ffffff;
  color: var(--header_tint);
  }
.Search--android.Search--default .Search__input::-webkit-input-placeholder {
  color: #aaaeb3;
  color: #aaaeb3;
  color: var(--icon_secondary);
  }
.Search--android.Search--default .Search__input::placeholder {
  color: #aaaeb3;
  color: #aaaeb3;
  color: var(--icon_secondary);
  }
.Tabs,
.Tabs .HorizontalScroll__in {
  display: flex;
  align-items: center;
  }
.Tabs .HorizontalScroll {
  min-width: 100%;
  }
.Tabs--default,
.Tabs--default .HorizontalScroll__in {
  justify-content: center;
  }
.Tabs--buttons,
.Tabs--default .HorizontalScroll__in {
  justify-content: flex-start;
  }
/*
 iOS
 */
.Tabs--ios.Tabs--default.Tabs--light {
  padding: 8px;
  background: #ebedf0;
  background: #ebedf0;
  background: var(--search_bar_background);
  }
.Tabs--ios.Tabs--default.Tabs--header {
  padding-top: 6px;
  }
.Tabs--ios.Tabs--buttons.Tabs--header {
  background: #5181b8;
  background: #5181b8;
  background: var(--header_background);
  }
.Tabs--ios.Tabs--buttons {
  padding-top: 8px;
  padding-bottom: 8px;
  }
.FixedLayout--ios.FixedLayout--top .Tabs::before {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1;
  height: 1px;
  content: '';
  bottom: 0;
  background: #d7d8d9;
  background: #d7d8d9;
  background: var(--separator_common);
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  }
.FixedLayout--ios.FixedLayout--bottom .Tabs::before {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1;
  height: 1px;
  content: '';
  bottom: 100%;
  background: #d7d8d9;
  background: #d7d8d9;
  background: var(--separator_common);
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  }
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .FixedLayout--ios.FixedLayout--top .Tabs::before,
  .FixedLayout--ios.FixedLayout--bottom .Tabs::before {
    -webkit-transform: scaleY(.5);
            transform: scaleY(.5);
    }
  }
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
  .FixedLayout--ios.FixedLayout--top .Tabs::before,
  .FixedLayout--ios.FixedLayout--bottom .Tabs::before {
    -webkit-transform: scaleY(.33);
            transform: scaleY(.33);
    }
  }
.FixedLayout--ios.FixedLayout--top .Tabs--light.Tabs--buttons {
  background: #ffffff;
  background: #ffffff;
  background: var(--background_content);
  }
.FixedLayout--ios.FixedLayout--bottom .Tabs {
  background: #ffffff;
  background: #ffffff;
  background: var(--header_alternate_background);
  }
/* Нужно для закрашивания прозрачной области нижнего инсета на iphone x */
.FixedLayout--ios.FixedLayout--bottom .Tabs::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: #ffffff;
  background-color: #ffffff;
  background-color: var(--header_alternate_background);
  }
.FixedLayout--ios.FixedLayout--bottom .TabsItem {
    position: relative;
    z-index: 2;
    }
.Tabs--ios.Tabs--buttons .HorizontalScroll__in::after,
.Tabs--ios.Tabs--buttons .HorizontalScroll__in::before {
  display: block;
  width: 8px;
  flex-shrink: 0;
  height: 1px;
  content: '';
  }
/*
  Android
 */
.Tabs--android {

  }
.Tabs--android.Tabs--light {
  background-color: #ffffff;
  background-color: #ffffff;
  background-color: var(--background_content);
  }
.Tabs--android.Tabs--header {
  background-color: #5181b8;
  background-color: #5181b8;
  background-color: var(--header_background);
  }
.Tabs--android.Tabs--buttons .HorizontalScroll__in::after,
.Tabs--android.Tabs--buttons .HorizontalScroll__in::before {
  display: block;
  width: 8px;
  flex-shrink: 0;
  height: 1px;
  content: '';
  }
.FixedLayout--top .Tabs--android,
.FixedLayout--bottom .Tabs--android {
  box-shadow: 0 0 4px rgba(0, 0, 0, .08), 0 4px 4px rgba(0, 0, 0, .16);
  }
.FixedLayout--android.FixedLayout--bottom .Tabs {
  background: #ffffff;
  background: #ffffff;
  background: var(--header_alternate_background);
  }
/**
 * Counter inside TabsItem
 */
.Tabs--android.Tabs--light .TabsItem .Counter {
  background: #aaaeb3;
  background: #aaaeb3;
  background: var(--counter_secondary_background);
  color: #ffffff;
  color: #ffffff;
  color: var(--counter_secondary_text);
  }
.Tabs--android.Tabs--light .TabsItem--selected .Counter {
  background: #528bcc;
  background: #528bcc;
  background: var(--counter_primary_background);
  color: #ffffff;
  color: #ffffff;
  color: var(--counter_primary_text);
  }
.Tabs--ios.Tabs--light .TabsItem .Counter {
  background: #99a2ad;
  background: #99a2ad;
  background: var(--segmented_control_tint);
  color: #ffffff;
  color: #ffffff;
  color: var(--background_content);
  }
.Tabs--ios.Tabs--light .TabsItem--selected .Counter {
  background: #ffffff;
  background: #ffffff;
  background: var(--background_content);
  color: #99a2ad;
  color: #99a2ad;
  color: var(--segmented_control_tint);
  }
.Tabs--ios.Tabs--buttons.Tabs--light .TabsItem .Counter {
  background: #76787a;
  background: #76787a;
  background: var(--panel_tab_inactive_text);
  color: #fff;
  color: #fff;
  color: var(--white);
  }
.Tabs--ios.Tabs--buttons.Tabs--light .TabsItem--selected .Counter {
  background: #5d5f61;
  background: #5d5f61;
  background: var(--panel_tab_active_text);
  color: #ebedf0;
  color: #ebedf0;
  color: var(--panel_tab_active_background);
  }
.Tabs--buttons.Tabs--header .TabsItem .Counter {
  background: rgba(194, 222, 255, 0.80);
  background: rgba(194, 222, 255, 0.80);
  background: var(--header_tab_inactive_text);
  color: #5181b8;
  color: #5181b8;
  color: var(--header_background);
  }
.Tabs--buttons.Tabs--header .TabsItem .Counter {
  background: rgba(194, 222, 255, 0.80);
  background: rgba(194, 222, 255, 0.80);
  background: var(--header_tab_inactive_text);
  color: #5181b8;
  color: #5181b8;
  color: var(--header_background);
  }
.Tabs--buttons.Tabs--header .TabsItem--selected .Counter {
  background: #ffffff;
  background: #ffffff;
  background: var(--header_tab_active_text);
  color: #5181b8;
  color: #5181b8;
  color: var(--header_background);
  }
.TabsItem {
  white-space: nowrap;
  text-align: center;
  font-weight: 500;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  }
.Tabs--default .TabsItem {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  }
.TabsItem__after {
    margin-left: 8px;
    }
/*
  iOS
 */
.Tabs--ios.Tabs--default .TabsItem {
  height: 32px;
  border: 1px solid;
  font-size: 14px;
  line-height: 18px;
  padding: 0 12px;
  }
.Tabs--ios.Tabs--default .TabsItem:not(:first-child) {
  border-left: none;
  }
.Tabs--ios.Tabs--default .TabsItem:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  }
.Tabs--ios.Tabs--default .TabsItem:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  }
.Tabs--ios.Tabs--default.Tabs--light .TabsItem {
  border-color: #99a2ad;
  border-color: #99a2ad;
  border-color: var(--segmented_control_tint);
  color: #99a2ad;
  color: #99a2ad;
  color: var(--segmented_control_tint);
  }
.Tabs--ios.Tabs--default.Tabs--light .TabsItem--selected {
  background-color: #99a2ad;
  background-color: #99a2ad;
  background-color: var(--segmented_control_tint);
  }
.Panel--tm-gray .Tabs--ios.Tabs--default.Tabs--light .TabsItem--selected {
  color: #ebedf0;
  color: #ebedf0;
  color: var(--background_page);
  }
.Panel--tm-white .Tabs--ios.Tabs--default.Tabs--light .TabsItem--selected {
  color: #ffffff;
  color: #ffffff;
  color: var(--background_content);
  }
.Tabs--ios.Tabs--default.Tabs--light .TabsItem:not(.TabsItem--selected).Tappable--active {
  background: #d7d8d9;
  background: #d7d8d9;
  background: var(--separator_common);
  }
.Tabs--ios.Tabs--default.Tabs--header .TabsItem:not(.TabsItem--selected).Tappable--active .TabsItem__in {
  opacity: .7;
  }
.Tabs--ios.Tabs--default.Tabs--header .TabsItem {
  border-color: #ffffff;
  border-color: #ffffff;
  border-color: var(--header_tint);
  color: #ffffff;
  color: #ffffff;
  color: var(--header_tint);
  }
.Tabs--ios.Tabs--default.Tabs--header .TabsItem--selected {
  background-color: #ffffff;
  background-color: #ffffff;
  background-color: var(--header_tint);
  color: #5181b8;
  color: #5181b8;
  color: var(--header_background);
  }
.Tabs--ios.Tabs--buttons .TabsItem {
  height: 30px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0 16px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  }
/* Для случая, когда внутри Tabs нет HorizontalScroll */
.Tabs--ios.Tabs--buttons > .TabsItem:first-child {
  margin-left: 8px;
  }
.Tabs--ios.Tabs--buttons .TabsItem:not(:last-child) {
  margin-right: 8px;
  }
.Tabs--ios.Tabs--buttons.Tabs--header .TabsItem {
  background-color: #5181b8;
  background-color: #5181b8;
  background-color: var(--header_background);
  color: rgba(194, 222, 255, 0.80);
  color: rgba(194, 222, 255, 0.80);
  color: var(--header_tab_inactive_text);
  }
.Tabs--ios.Tabs--buttons.Tabs--header .TabsItem--selected {
  background-color: rgba(173, 211, 255, 0.32);
  background-color: rgba(173, 211, 255, 0.32);
  background-color: var(--header_tab_active_background);
  color: #ffffff;
  color: #ffffff;
  color: var(--header_tab_active_text);
  }
.Tabs--ios.Tabs--buttons.Tabs--light .TabsItem {
  color: #76787a;
  color: #76787a;
  color: var(--panel_tab_inactive_text);
  }
.Tabs--ios.Tabs--buttons.Tabs--light .TabsItem--selected {
  background-color: #ebedf0;
  background-color: #ebedf0;
  background-color: var(--panel_tab_active_background);
  color: #5d5f61;
  color: #5d5f61;
  color: var(--panel_tab_active_text);
  }
/*
  Android
 */
.Tabs--android .TabsItem {
  height: 48px;
  font-size: 14px;
  position: relative;
  }
.Tabs--android .TabsItem--selected::after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    display: block;
    content: '';
    }
.Tabs--android.Tabs--light .TabsItem {
  color: #909499;
  color: #909499;
  color: var(--text_secondary);
  }
.Tabs--android.Tabs--light .TabsItem.Tappable--active {
  background: rgba(0, 0, 0, 0.12);
  background: rgba(0, 0, 0, 0.12);
  background: var(--background_highlighted);
  }
.Tabs--android.Tabs--light .TabsItem--selected {
  color: #528bcc;
  color: #528bcc;
  color: var(--accent);
  }
.Tabs--android.Tabs--light .TabsItem--selected::after {
    background-color: #528bcc;
    background-color: #528bcc;
    background-color: var(--accent);
    }
.Tabs--android.Tabs--header .TabsItem {
  color: #ffffff;
  color: #ffffff;
  color: var(--header_tint);
  }
.Tabs--android.Tabs--header .TabsItem.Tappable--active {
  background-color: rgba(255, 255, 255, .1);
  }
.Tabs--android.Tabs--header .TabsItem .TabsItem__in {
    opacity: .7;
    text-transform: uppercase;
    }
.Tabs--android.Tabs--header .TabsItem--selected .TabsItem__in {
    opacity: 1;
    }
.Tabs--android.Tabs--header .TabsItem--selected::after {
    background-color: #ffffff;
    background-color: #ffffff;
    background-color: var(--header_tint);
    }
.Tabs--android.Tabs--header .TabsItem.Tappable--active {
  background-color: var(--white_alpha12);
  }
.Tabs--android.Tabs--buttons .TabsItem {
  padding-left: 12px;
  padding-right: 12px;
  }
.Tooltip {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  }
.Tooltip__container {
    position: absolute;
    }
.Tooltip__corner {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyMHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDIwIDgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+dG9vbHRpcF90aXA8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0iTWFzdGVyLUtpdCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8cGF0aCBkPSJNMTAsMCBDMTMuNSwwIDE1LjUsOCAyMCw4IEwwLDggQzQuNSw4IDYuNSwwIDEwLDAgWiIgaWQ9InRvb2x0aXBfdGlwIiBmaWxsPSIjNDdhM2ZmIiBmaWxsLXJ1bGU9Im5vbnplcm8iPjwvcGF0aD4gICAgPC9nPjwvc3ZnPg==);
      width: 20px;
      height: 8px;
      position: absolute;
      }
.Tooltip--y-bottom .Tooltip__corner {
      bottom: 100%;
      }
.Tooltip--y-top .Tooltip__corner {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      top: 100%;
      }
.Tooltip__content {
      background-color: #47a3ff;
      box-shadow: 0 4px 32px 0 rgba(0, 0, 0, .16), 0 0 4px 0 rgba(0, 0, 0, .04);
      padding: 6px 16px;
      border-radius: 10px;
      font-size: 14px;
      color: #fff;
      line-height: 1.29;
      max-width: 220px;
      }
.Tooltip__title {
        font-weight: 600;
        }
.Tooltip__text {

        }
.PullToRefresh {
  height: 100%;
  }
.PullToRefresh--refreshing {
  touch-action: none;
  pointer-events: none;
  }
.PullToRefresh__controls {
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: 100;
  }
.PullToRefresh--ios .PullToRefresh__controls {
  z-index: 0;
  }
.PullToRefresh__spinner {
  display: flex;
  margin: auto;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: opacity 220ms ease-out, -webkit-transform 300ms cubic-bezier(.1, 0, .25, 1);
  transition: transform 300ms cubic-bezier(.1, 0, .25, 1), opacity 220ms ease-out;
  transition: transform 300ms cubic-bezier(.1, 0, .25, 1), opacity 220ms ease-out, -webkit-transform 300ms cubic-bezier(.1, 0, .25, 1);
  opacity: 0;
  }
.PullToRefresh--ios .PullToRefresh__spinner {
  color: #aeb7c2;
  color: #aeb7c2;
  color: var(--activity_indicator_tint);
  }
.PullToRefresh--android .PullToRefresh__spinner {
  background: #ffffff;
  background: #ffffff;
  background: var(--background_suggestions);
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
  color: #528bcc;
  color: #528bcc;
  color: var(--accent);
  }
.PullToRefresh--watching .PullToRefresh__spinner {
  transition: opacity 220ms ease-out;
  }
.PullToRefresh__spinner-self {
  stroke: currentColor;
  }
.PullToRefresh--ios.PullToRefresh--refreshing .PullToRefresh__spinner-self {
  -webkit-animation: PullToRefreshToRefreshing 380ms ease-out;
          animation: PullToRefreshToRefreshing 380ms ease-out;
  }
.PullToRefresh__spinner-path {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: center center;
          transform-origin: center center;
  transition: stroke-dashoffset 167ms ease-out;
  }
.PullToRefresh--watching .PullToRefresh__spinner-path,
.PullToRefresh--refreshing .PullToRefresh__spinner-path {
  transition: none;
  }
.PullToRefresh__spinner--on .PullToRefresh__spinner-path {
  -webkit-animation: rotator .7s linear infinite;
          animation: rotator .7s linear infinite;
  -webkit-animation: rotator .7s linear infinite;
  -webkit-animation: rotator var(--duration) linear infinite;
          animation: rotator .7s linear infinite;
          animation: rotator var(--duration) linear infinite;
  }
.PullToRefresh__content {
  overflow: hidden;
  transition: -webkit-transform 400ms cubic-bezier(.36, .66, .04, 1);
  transition: transform 400ms cubic-bezier(.36, .66, .04, 1);
  transition: transform 400ms cubic-bezier(.36, .66, .04, 1), -webkit-transform 400ms cubic-bezier(.36, .66, .04, 1);
  transition: -webkit-transform 400ms cubic-bezier(.36, .66, .04, 1);
  transition: -webkit-transform 400ms var(--ios-easing);
  transition: transform 400ms cubic-bezier(.36, .66, .04, 1);
  transition: transform 400ms var(--ios-easing);
  transition: transform 400ms cubic-bezier(.36, .66, .04, 1), -webkit-transform 400ms cubic-bezier(.36, .66, .04, 1);
  transition: transform 400ms var(--ios-easing), -webkit-transform 400ms var(--ios-easing);
  }
.PullToRefresh--watching .PullToRefresh__content {
  transition: none;
  }
@-webkit-keyframes PullToRefreshToRefreshing {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    }

  30% {
    -webkit-transform: scale(.6);
            transform: scale(.6);
    }

  90% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    }
  }
@keyframes PullToRefreshToRefreshing {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    }

  30% {
    -webkit-transform: scale(.6);
            transform: scale(.6);
    }

  90% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    }
  }
.Counter {
  display: flex;
  align-items: center;
  justify-content: center;
  }
.Counter__in {
    font-size: 14px;
    line-height: 1.2;
    padding: 0 5px;
    text-align: center;
    }
.Counter--primary {
  background: #528bcc;
  background: #528bcc;
  background: var(--counter_primary_background);
  color: #ffffff;
  color: #ffffff;
  color: var(--counter_primary_text);
  }
.Counter--secondary {
  background: #aaaeb3;
  background: #aaaeb3;
  background: var(--counter_secondary_background);
  color: #ffffff;
  color: #ffffff;
  color: var(--counter_secondary_text);
  }
.Counter--prominent {
  background: #e64646;
  background: #e64646;
  background: var(--destructive);
  color: #fff;
  color: #fff;
  color: var(--white);
  }
.Counter--ios {
  min-width: 22px;
  height: 22px;
  border-radius: 11px;
  }
.Counter--android {
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  }
.Counter--android .Counter__in {
    font-weight: 500;
    }
.UsersStack {
  display: flex;
  align-items: center;
  color: #909499;
  color: #909499;
  color: var(--text_secondary);
}
.UsersStack--v {
  flex-direction: column;
  justify-content: center;
}
.UsersStack--ios {
  padding: 8px 12px;
}
.UsersStack--android {
  padding: 8px 16px;
}
.UsersStack__photos {
  display: flex;
}
.UsersStack__photo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}
.UsersStack__photo--others {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: #99a2ad;
  background: #99a2ad;
  background: var(--content_placeholder_icon);
}
.UsersStack__photo + .UsersStack__photo {
  margin-left: -2px;
}
.UsersStack--size-s .UsersStack__photo {
  width: 24px;
  height: 24px;
}
.UsersStack--size-s .UsersStack__photo + .UsersStack__photo {
  -webkit-clip-path: url(#users_stack_mask_24_left);
          clip-path: url(#users_stack_mask_24_left);
}
.UsersStack--size-m .UsersStack__photo {
  width: 32px;
  height: 32px;
}
.UsersStack--size-m .UsersStack__photo + .UsersStack__photo {
  -webkit-clip-path: url(#users_stack_mask_32_left);
          clip-path: url(#users_stack_mask_32_left);
}
.UsersStack--with-others.UsersStack--size-m .UsersStack__photo {
  -webkit-clip-path: url(#users_stack_mask_32_right);
          clip-path: url(#users_stack_mask_32_right);
}
.UsersStack--with-others.UsersStack--size-m .UsersStack__photo:last-child {
  -webkit-clip-path: none;
          clip-path: none;
}
.UsersStack__text {
  min-width: 0;
  flex: 1 1;
  margin: 0 0 0 8px;
  font-size: 13px;
  line-height: 16px;
}
.UsersStack--v .UsersStack__text {
  margin: 8px 0 0;
  text-align: center;
}
/* Forms */
.FormLayout {
  position: relative;
  }
.FormLayout__row {
    display: block;
    }
.FormLayout__row-top {
    font-size: 14px;
    line-height: 18px;
    color: #909499;
    color: #909499;
    color: var(--text_secondary);
    }
.FormLayout__row-bottom {
    font-size: 13px;
    line-height: 16px;
    color: #909499;
    color: #909499;
    color: var(--text_secondary);
    }
.FormLayout__submit {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
    left: 0;
    top: 0;
    padding: 0;
    width: 0;
    height: 0;
    -webkit-appearance: none;
            appearance: none;
    border: none;
    }
.FormLayout--ios {}
.FormLayout--ios .FormLayout__row {
    padding: 12px 0;
    }
.FormLayout--ios .FormLayout__row .Checkbox:first-child,
    .FormLayout--ios .FormLayout__row .Radio:first-child,
    .FormLayout--ios .FormLayout__row .Cell:first-child,
    .FormLayout--ios .FormLayout__row .CellButton:first-child {
      margin-top: -4px;
      }
.FormLayout--ios .FormLayout__row .Checkbox:last-child,
    .FormLayout--ios .FormLayout__row .Radio:last-child,
    .FormLayout--ios .FormLayout__row .Cell:last-child,
    .FormLayout--ios .FormLayout__row .CellButton:last-child {
      margin-bottom: -4px;
      }
.FormLayout--ios .FormLayout__row .Button--sz-xl {
      width: calc(100% - 2 * 12px);
      }
.FormLayout--ios .FormLayout__row .FormField,
    .FormLayout--ios .FormLayout__row .Button,
    .FormLayout--ios .FormLayout__row .FormStatus {
      margin-left: 12px;
      margin-right: 12px;
      }
.FormLayout--ios .FormLayout__row .Slider,
    .FormLayout--ios .FormLayout__row .RangeSlider {
      margin-left: 26px;
      margin-right: 26px;
      }
.FormLayout--ios .FormLayout__row-top {
      padding: 2px 12px 8px;
      }
.FormLayout--ios .FormLayout__row-bottom {
      padding: 8px 12px 0;
      }
.FormLayout--android {}
.FormLayout--android .FormLayout__row {
    padding: 10px 0;
    }
.FormLayout--android .FormLayout__row .Checkbox:first-child,
    .FormLayout--android .FormLayout__row .Radio:first-child,
    .FormLayout--android .FormLayout__row .Cell:first-child,
    .FormLayout--android .FormLayout__row .CellButton:first-child {
      margin-top: -4px;
      }
.FormLayout--android .FormLayout__row .Checkbox:last-child,
    .FormLayout--android .FormLayout__row .Radio:last-child,
    .FormLayout--android .FormLayout__row .Cell:last-child,
    .FormLayout--android .FormLayout__row .CellButton:last-child {
      margin-bottom: -4px;
      }
.FormLayout--android .FormLayout__row .Button--sz-xl {
      width: calc(100% - 2 * 16px);
      }
.FormLayout--android .FormLayout__row .FormField,
    .FormLayout--android .FormLayout__row .Button,
    .FormLayout--android .FormLayout__row .FormStatus {
      margin-left: 16px;
      margin-right: 16px;
      }
.FormLayout--android .FormLayout__row .Slider,
    .FormLayout--android .FormLayout__row .RangeSlider {
      margin-left: 30px;
      margin-right: 30px;
      }
.FormLayout--android .FormLayout__row-top {
      padding: 2px 16px 8px;
      }
.FormLayout--android .FormLayout__row-bottom {
      padding: 8px 16px 0;
      }
.FormLayout__row--s-error .FormLayout__row-bottom {
      color: #e64646;
      color: #e64646;
      color: var(--field_error_border);
      }
.FormLayout__row--s-valid .FormLayout__row-bottom,
    .FormLayout__row--s-verified .FormLayout__row-bottom {
      color: #4bb34b;
      color: #4bb34b;
      color: var(--field_valid_border);
      }
.FormLayoutGroup > * ~ * {
  margin-top: 12px;
  }
.FormLayoutGroup > .Radio,
.FormLayoutGroup > .Checkbox,
.FormLayoutGroup > .Cell,
.FormLayoutGroup > .CellButton {
  margin-top: 0;
  }
.FormStatus {
  border-radius: 10px;
  padding: 14px 12px;
  background: #f2f3f5;
  background: #f2f3f5;
  background: var(--field_background);
  color: #000000;
  color: #000000;
  color: var(--text_primary);
  }
.FormStatus--error {
  background: #faebeb;
  background: #faebeb;
  background: var(--field_error_background);
  color: #e64646;
  color: #e64646;
  color: var(--field_error_border);
  }
.FormStatus__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    margin-top: -2px;
    }
.FormStatus__title:not(:last-child) {
    margin-bottom: 2px;
    }
.FormStatus__content {
    font-size: 13px;
    line-height: 16px;
    }
.FormStatus__content b {
      font-weight: 500;
      }
.Slider {
  position: relative;
  height: 28px;
  margin: 0 14px;
  }
.Slider::before {
    position: absolute;
    left: 0;
    top: 12px;
    height: 4px;
    width: 100%;
    background-color: rgba(0, 29, 61, 0.08);
    background-color: rgba(0, 29, 61, 0.08);
    background-color: var(--placeholder_icon_background);
    border-radius: 2px;
    content: '';
    }
.Slider__in {
    height: 100%;
    }
.Slider__dragger {
    position: absolute;
    top: 12px;
    left: 0;
    width: 0;
    height: 0;
    }
.Slider__dragger::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: #528bcc;
    background: #528bcc;
    background: var(--accent);
    border-radius: 2px;
    content: '';
    }
.Slider__thumb {
    position: absolute;
    top: -12px;
    width: 28px;
    height: 28px;
    transition: -webkit-transform .1s ease;
    transition: transform .1s ease;
    transition: transform .1s ease, -webkit-transform .1s ease;
    }
.Slider__thumb--start {
      left: -14px;
      }
.Slider__thumb--end {
      right: -14px;
      }
/**
 * iOS
 */
.Slider--ios {
  }
.Slider--ios .Slider__in {}
.Slider--ios .Slider__thumb {
      border-radius: 14px;
      background: #fff;
      background: #fff;
      background: var(--white);
      box-shadow: 0 2px 7px rgba(0, 0, 0, .35), 0 1px 1px rgba(0, 0, 0, .15);
      }
/**
 * Android
 */
.Slider--android {
  }
.Slider--ios .Slider__in {}
.Slider--android .Slider__thumb::before {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 20px;
      height: 20px;
      content: '';
      background: #fff;
      background: #fff;
      background: var(--white);
      border-radius: 10px;
      box-shadow: 0 1px 3px 0 #d7d8d9, 0 0 1px 0 #d7d8d9, 0 0 0 .5px rgba(0, 0, 0, .04);
      box-shadow: 0 1px 3px 0 #d7d8d9, 0 0 1px 0 #d7d8d9, 0 0 0 .5px rgba(0, 0, 0, .04);
      box-shadow: 0 1px 3px 0 var(--separator_common), 0 0 1px 0 var(--separator_common), 0 0 0 .5px rgba(0, 0, 0, .04);
      }
.FormField {
  position: relative;
  }
.FormField__border {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border: 1px solid var(--field_border);
  background: #f2f3f5;
  background: #f2f3f5;
  background: var(--field_background);
  content: '';
  border-radius: 10px;
  box-sizing: border-box;
  z-index: 1;
  -webkit-transform-origin: left top;
          transform-origin: left top;
  }
.FormField--ios .FormField__border {
  border-radius: 10px;
  }
.FormField--android .FormField__border {
  border-radius: 8px;
  }
.FormField--s-error .FormField__border {
  background: #faebeb;
  background: #faebeb;
  background: var(--field_error_background);
  border-color: #e64646;
  border-color: #e64646;
  border-color: var(--field_error_border);
  }
.FormField--s-valid .FormField__border,
.FormField--s-verified .FormField__border {
  border-color: #4bb34b;
  border-color: #4bb34b;
  border-color: var(--field_valid_border);
  }
.FormField.Textarea .Textarea__el:disabled ~ .FormField__border,
.FormField.Select .Select__el:disabled ~ .FormField__border,
.FormField.Input .Input__el:disabled ~ .FormField__border {
  opacity: .6;
  }
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .FormField--ios .FormField__border {
    border-radius: 20px;
    -webkit-transform: scale(.5);
            transform: scale(.5);
    width: 200%;
    height: 200%;
    }
  }
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
  .FormField--ios .FormField__border {
    border-radius: 31.25px;
    -webkit-transform: scale(.32);
            transform: scale(.32);
    width: 312.5%;
    height: 312.5%;
    }
  }
.FormField.Input .Input__el:focus ~ .FormField__border,
.FormField.Textarea .Textarea__el:focus ~ .FormField__border,
.FormField.Select .Select__el:focus ~ .FormField__border,
.FormField.Select--mimicry:focus .FormField__border {
  border-color: #528bcc;
  border-color: #528bcc;
  border-color: var(--accent);
  background: #f2f3f5;
  background: #f2f3f5;
  background: var(--field_background);
  }
.FormField--ios.Input .Input__el:focus ~ .FormField__border,
.FormField--ios.Textarea .Textarea__el:focus ~ .FormField__border,
.FormField--ios.Select .Select__el:focus ~ .FormField__border,
.FormField--ios.Select--mimicry:focus .FormField__border {
  border-radius: 10px;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
  height: 100%;
  }
.Radio {
  display: block;
  }
.Radio__input {
    display: none;
    }
.Radio__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    }
.Radio__input:disabled ~ .Radio__container {
    opacity: .6;
    }
.Radio__icon {
      flex-shrink: 0;
      box-sizing: border-box;
      border-radius: 50%;
      position: relative;
      }
.Radio__icon::after {
        position: absolute;
        content: '';
        display: block;
        border-radius: 50%;
        box-sizing: border-box;
        }
.Radio__content {
      line-height: 20px;
      font-size: 16px;
      flex-grow: 1;
      max-width: 100%;
      color: #000000;
      color: #000000;
      color: var(--text_primary);
      }
.Radio__description {
        font-size: 13px;
        line-height: 15px;
        color: #909499;
        color: #909499;
        color: var(--text_secondary);
        }
.Radio--ios {
  padding: 10px 12px;
  }
.Radio--ios .Radio__container {}
.Radio--ios .Radio__icon {
      width: 22px;
      height: 22px;
      border: 2px solid #aaaeb3;
      border: 2px solid #aaaeb3;
      border: 2px solid var(--icon_secondary);
      margin: 1px 12px 1px 1px;
      transition: border-color .15s cubic-bezier(.36, .66, .04, 1);
      transition: border-color .15s cubic-bezier(.36, .66, .04, 1);
      transition: border-color .15s var(--ios-easing);
      }
.Radio--ios .Radio__input:checked ~ .Radio__container .Radio__icon {
      border-color: #528bcc;
      border-color: #528bcc;
      border-color: var(--accent);
      }
.Radio--ios .Radio__icon::after {
        width: 14px;
        height: 14px;
        left: 2px;
        top: 2px;
        -webkit-transform: scale(.3);
                transform: scale(.3);
        transition: -webkit-transform .15s cubic-bezier(.36, .66, .04, 1);
        transition: transform .15s cubic-bezier(.36, .66, .04, 1);
        transition: transform .15s cubic-bezier(.36, .66, .04, 1), -webkit-transform .15s cubic-bezier(.36, .66, .04, 1);
        transition: -webkit-transform .15s cubic-bezier(.36, .66, .04, 1);
        transition: -webkit-transform .15s var(--ios-easing);
        transition: transform .15s cubic-bezier(.36, .66, .04, 1);
        transition: transform .15s var(--ios-easing);
        transition: transform .15s cubic-bezier(.36, .66, .04, 1), -webkit-transform .15s cubic-bezier(.36, .66, .04, 1);
        transition: transform .15s var(--ios-easing), -webkit-transform .15s var(--ios-easing);
        }
.Radio--ios .Radio__input:checked ~ .Radio__container .Radio__icon::after {
        background-color: #528bcc;
        background-color: #528bcc;
        background-color: var(--accent);
        -webkit-transform: scale(1);
                transform: scale(1);
        }
.Radio--android .Radio__content {
      margin: 1px 0;
      }
.Radio--android {
  padding: 12px 16px;
  }
.Radio--android .Radio__container {}
.Radio--android .Radio__icon {
      width: 20px;
      height: 20px;
      border: 2px solid #aaaeb3;
      border: 2px solid #aaaeb3;
      border: 2px solid var(--icon_secondary);
      margin: 2px 14px 2px 2px;
      transition: border-color .5s cubic-bezier(.36, .66, .04, 1);
      transition: border-color .5s cubic-bezier(.36, .66, .04, 1);
      transition: border-color .5s var(--ios-easing);
      }
.Radio--android .Radio__icon::after {
        width: 10px;
        height: 10px;
        left: 3px;
        top: 3px;
        -webkit-transform: scale(.3);
                transform: scale(.3);
        transition: -webkit-transform .5s cubic-bezier(.36, .66, .04, 1);
        transition: transform .5s cubic-bezier(.36, .66, .04, 1);
        transition: transform .5s cubic-bezier(.36, .66, .04, 1), -webkit-transform .5s cubic-bezier(.36, .66, .04, 1);
        transition: -webkit-transform .5s cubic-bezier(.36, .66, .04, 1);
        transition: -webkit-transform .5s var(--ios-easing);
        transition: transform .5s cubic-bezier(.36, .66, .04, 1);
        transition: transform .5s var(--ios-easing);
        transition: transform .5s cubic-bezier(.36, .66, .04, 1), -webkit-transform .5s cubic-bezier(.36, .66, .04, 1);
        transition: transform .5s var(--ios-easing), -webkit-transform .5s var(--ios-easing);
        }
.Radio--android .Radio__content {
      margin: 2px 0;
      }
.Radio--android .Radio__input:checked ~ .Radio__container .Radio__icon {
      border-color: #528bcc;
      border-color: #528bcc;
      border-color: var(--accent);
      }
.Radio--android .Radio__input:checked ~ .Radio__container .Radio__icon::after {
        background-color: #528bcc;
        background-color: #528bcc;
        background-color: var(--accent);
        -webkit-transform: scale(1);
                transform: scale(1);
        }
.Checkbox {
  display: block;
  }
.Checkbox__input {
    display: none;
    }
.Checkbox__container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    }
.Checkbox__icon {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      margin: 0 14px 0 2px;
      border-radius: 4px;
      border: 2px solid #aaaeb3;
      border: 2px solid #aaaeb3;
      border: 2px solid var(--icon_secondary);
      color: transparent;
      }
.Checkbox__content {
      flex-grow: 1;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      color: #000000;
      color: var(--text_primary);
      }
.Checkbox__input:checked ~ .Checkbox__container .Checkbox__icon {
    background-color: #528bcc;
    background-color: #528bcc;
    background-color: var(--accent);
    border-color: #528bcc;
    border-color: #528bcc;
    border-color: var(--accent);
    color: #fff;
    color: #fff;
    color: var(--white);
    }
.Checkbox__input[disabled] ~ .Checkbox__container {
    opacity: .6;
    }
.Checkbox--ios {
  padding: 12px;
  }
.Checkbox--ios .Checkbox__container {}
.Checkbox--ios .Checkbox__content {
      font-size: 16px;
      line-height: 20px;
      }
.Checkbox--android {
  padding: 16px;
  }
.Checkbox--android .Checkbox__container {}
.Checkbox--android .Checkbox__content {
      font-size: 16px;
      line-height: 20px;
      }
.Input {
  position: relative;
  line-height: 19px;
  font-size: 16px;
  }
.Input--center .Input__el {
  text-align: center;
  }
.Input--right .Input__el {
  text-align: right;
  }
.Input__el {
  margin: 0;
  width: 100%;
  display: block;
  box-sizing: border-box;
  box-shadow: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  text-overflow: ellipsis;
  height: 44px;
  line-height: 19px;
  font-size: 16px;
  color: #000000;
  color: #000000;
  color: var(--text_primary);
  padding: 12px;
  position: relative;
  z-index: 2;
  background: transparent;
  }
.Input__el:disabled {
  opacity: .6;
  }
.Input__el::-webkit-input-placeholder {
  color: #909499;
  color: #909499;
  color: var(--field_text_placeholder);
  }
.Input__el::placeholder {
  color: #909499;
  color: #909499;
  color: var(--field_text_placeholder);
  }
.Select {
  display: block;
  position: relative;
  box-sizing: border-box;
  }
.Select--mimicry:focus {
  outline: none;
  }
.Select select {
    display: block;
    position: absolute;
    -webkit-appearance: none;
            appearance: none;
    outline: none;
    border: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 3;
    font-size: 16px;
    }
.Select__container {
    line-height: 19px;
    font-size: 16px;
    padding: 13px 40px 12px 12px;
    color: #000000;
    color: #000000;
    color: var(--text_primary);
    box-sizing: border-box;
    height: 44px;
    position: relative;
    z-index: 2;
    }
.Select--disabled .Select__container,
  .Select select:disabled ~ .Select__container {
    opacity: .6;
    }
.Select:not(.Select--multiline) .Select__title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      }
.Select--not-selected .Select__title {
      color: #909499;
      color: #909499;
      color: var(--field_text_placeholder);
      }
.Select--align-right .Select__title {
      text-align: right;
      }
.Select--align-center .Select__title {
      text-align: center;
      }
.Select .Icon {
      position: absolute;
      top: 50%;
      margin-top: -12px;
      right: 10px;
      color: #aaaeb3;
      color: #aaaeb3;
      color: var(--icon_secondary);
      }
.Select--multiline .Select__container {
  height: auto;
  min-height: 44px;
  }
.Textarea {
  position: relative;
  }
.Textarea textarea {
    margin: 0;
    width: 100%;
    display: block;
    box-sizing: border-box;
    outline: none;
    resize: none;
    -webkit-appearance: none;
            appearance: none;
    line-height: 19px;
    font-size: 16px;
    color: #000000;
    color: #000000;
    color: var(--text_primary);
    padding: 12px;
    min-height: 86px;
    max-height: 198px;
    border: none;
    z-index: 2;
    position: relative;
    background: transparent;
    }
.Textarea textarea::-webkit-input-placeholder {
    color: #909499;
    color: #909499;
    color: var(--field_text_placeholder);
    }
.Textarea textarea::placeholder {
    color: #909499;
    color: #909499;
    color: var(--field_text_placeholder);
    }
.Textarea textarea:disabled {
    opacity: .6;
    }
.File {}
.File__input {
      display: none;
      }
html,
body,
div#root {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: -apple-system, Roboto, Helvetica Neue, Arial;
  font-family: -apple-system, Roboto, Helvetica Neue, Arial;
  font-family: var(--font-common);
}
body {
  overflow-x: hidden;
}
input,
textarea,
select,
button {
  font-family: inherit;
}
#root {
  -webkit-user-select: none;
          user-select: none;
}
a:focus {
  outline: none;
}


/*# sourceMappingURL=vkui.css.map*/
